<template>
  <ClientSkeleton v-if="isLoading" class="px-6 pb-6 pt-5" />
  <div class="container is-fluid pt-5 pb-6 px-6 min-h-90vh" v-else>
    <div class="is-flex is-justify-content-space-between mb-5">
      <div class="is-flex is-align-items-center is-clickable" @click="goBack()">
        <ArrowLeftIcon class="mr-3" />
        <p class="averta-bold is-size-5">Kembali</p>
      </div>
      <div class="is-flex is-align-items-center">
        <div class="mr-5 input-custom-1">
          <b-input
            :placeholder="`Cari nama ${renderTitle}`"
            type="search"
            icon="magnify"
            :value="getSearchValue"
            @input="updateSearchValue"
            @keyup.native.enter="tabActive === 'unit' ? getListBranch({dontLoading: true}) : filterAreaK()"
          >
          </b-input>
        </div>
        <div>
          <ButtonApp
            v-if="tabActive === 'unit'"
            @click="handleAddBranch"
            :is-bold="true"
            height="40px"
            width="254px"
          >
            + Tambah {{renderTitleBranch}}
          </ButtonApp>
          <ButtonApp
            v-if="isSMRC && tabActive === 'areaCoordinator'"
            @click="handleAddAreaCoordinator"
            :is-bold="true"
            height="40px"
            width="254px"
          >
            + Tambah Area Koordinator
          </ButtonApp>
        </div>
      </div>
    </div>
    <BannerClient :client="dataClient" :refreshData="getListBranch" />
    <div v-if="tabActive !== 'areaCoordinator'" class="columns">
      <div class="column card col-1 p-3 m-2" v-for="(item, index) in getListDaftarBranchData" :key="index">
      <img :src="require(`@/assets/img/${item.image}`)"  alt="wave" class="wave" />
          <p class="is-size-5 averta-bold">{{ item.total }}</p>
          <p class="is-size-4 averta-black my-2">{{ item.listBranch }}</p>
      </div>
    </div>

    <div v-if="isSMRC" class="flex-center-vertical mb-5">
      <div class="btn-tabs-2 mr-3" @click="handleClickTab('unit')" :class="{'is-active': tabActive === 'unit'}">
        <p>Daftar Unit</p>
      </div>
      <div class="btn-tabs-2" @click="handleClickTab('areaCoordinator')" :class="{'is-active': tabActive === 'areaCoordinator'}">
        <p>Daftar Area Koordinator</p>
      </div>
    </div>

    <TabListAreaBranch
      v-if="tabActive === 'unit'"
      :listBranch="listBranch"
      :refreshData="getListBranch"
      :isSMRC="isSMRC"
      @detailBranch="detailBranch($event)"
      @handleEditBranch="handleEditBranch($event)"
    />

    <TabListAreaKoordinator
      v-if="isSMRC && tabActive === 'areaCoordinator'"
      :data="dataAreaKoordinator"
      :refreshData="getListAreaKoordinator"
    />

    <!-- <div v-if="!isSMRC && listBranch.list.length > 0" class="columns is-flex is-flex-wrap-wrap">
      <div
        class="column is-3"
        v-for="(branch, index) in listBranch.list"
        :key="index"
      >
        <div class="card border-radius-20 border-1-grey-2 list-branch p-4">
          <div class="branch-name mb-4">
            <p class="averta-bold label-20">Cabang {{ branch.name }}</p>
          </div>
          <div
            class="is-flex is-justify-content-space-around wrapper-vendor p-3"
          >
            <div class="has-text-centered flex-1">
              <p>Jumlah Toko</p>
              <p class="averta-bold">{{ branch.total_store }}</p>
            </div>
            <div class="border-right"></div>
            <div class="has-text-centered flex-1">
              <p>Jumlah PIC</p>
              <p class="averta-bold">{{ branch.total_pic }}</p>
            </div>
          </div>
          <div class="mt-5 is-flex is-justify-content-space-around">
            <ButtonApp
              height="45px"
              width="100%"
              class="mr-3"
              :is-bold="true"
              :is-secondary="true"
              @click="handleEditBranch(branch)"
              >Edit</ButtonApp
            >
            <ButtonApp
              height="45px"
              width="100%"
              :is-bold="true"
              @click="detailBranch(branch.id, branch)"
              >Lihat Detail</ButtonApp
            >
          </div>
        </div>
      </div>
    </div> -->

    <div v-else-if="!isSMRC" class="column c-w-100 has-text-centered" style="display: none;">
      <ProyekEmptyIcon />
      <p class="averta-bold mt-2">
        Data Tidak Ditemukan
      </p>
    </div>

    <SidebarBranch
      :show="showSidebar"
      :close="handleCloseSidebar"
      @refreshListBranch="getListBranch"
    />
    <SidebarEditBranch
      :show="showSidebarEdit"
      :close="handleCloseSidebarEdit"
      :selectedBranch="selectedBranch"
      @updateBranch="getListBranch"
    />
    <SidebarAddPICBranch
      type="area-koordinator"
      :show="showSidebarCoordinator"
      :close="handleCloseSidebarAreaCoordinator"
      @reloadData="getListAreaKoordinator($event)"
    />

    <b-loading :is-full-page="true" v-model="isLoadingAreaC" :can-cancel="false">
      <SpinnerIcon />
    </b-loading>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  AREACOORD,
  BRANCH_SMRC,
  BRANCH,
  AREA_KOORDINATOR_SMRC,
  PIC_STORE
} from '@/helpers/constants'
import { titleBranch, alertErrorMessage, isSMRC } from '@/helpers'

import { ArrowLeftIcon, ProyekEmptyIcon, SpinnerIcon } from '@/components/icons'
import { ListDaftarBranchData } from '@/data/views/listbranchData'

import ButtonApp from '@/components/button/ButtonApp.vue'
import BannerClient from './components/BannerClient.vue'
import ClientSkeleton from '@/components/skeleton/ClientSkeleton.vue'

import SidebarBranch from './components/SidebarBranch.vue'
import SidebarAddPICBranch from './components/branch-components/SidebarAddPICBranch.vue'
import SidebarEditBranch from './components/branch-components/SidebarEditBranch.vue'

import TabListAreaBranch from './components/store-components/TabListAreaBranch.vue'
import TabListAreaKoordinator from './components/area-koordinator-components/TabListAreaKoordinator.vue'

export default {
  name: 'ListBranch',
  components: {
    ArrowLeftIcon,
    ProyekEmptyIcon,
    SpinnerIcon,

    ButtonApp,
    BannerClient,
    ClientSkeleton,

    SidebarBranch,
    SidebarEditBranch,
    SidebarAddPICBranch,

    TabListAreaBranch,
    TabListAreaKoordinator

  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    pathDetailBranch () {
      const path = this.$route.path.split('/')
      const lastElement = path[path.length - 1]

      if (lastElement === 'detail-branch') return true
      return false
    },
    getListDaftarBranchData () {
      return ListDaftarBranchData(this.listBranch)
    },
    renderTitleBranch () {
      return titleBranch(this.listBranch.code || '')
    },
    isSMRC () {
      return isSMRC(this.listBranch.code)
    },
    renderTitle () {
      let temp
      if (this.isSMRC) {
        if (this.tabActive === 'unit') {
          temp = BRANCH_SMRC
        } else {
          temp = AREA_KOORDINATOR_SMRC
        }
      } else {
        if (this.tabActive === 'unit') {
          temp = BRANCH
        } else {
          temp = PIC_STORE
        }
      }
      return temp
    },
    getSearchValue () {
      if (this.tabActive === 'unit') {
        return this.searchUnit
      } else {
        return this.searchAreaK
      }
    }
  },
  data () {
    return {
      isLoading: false,
      isLoadingAreaC: false,
      searchUnit: null,
      searchAreaK: null,
      listBranch: null,
      showSidebar: false,
      showSidebarEdit: false,
      showSidebarCoordinator: false,
      selectedBranch: null,
      dataClient: null,
      tabActive: 'unit',
      initAreaKoordinator: [],
      dataAreaKoordinator: []
    }
  },

  methods: {
    handleClickTab (tab) {
      this.tabActive = tab
    },
    goBack () {
      this.$router.go(-1)

      const clearBranch = {
        type: 'clearPayload',
        data: {
          clientSelected: {},
          branchName: '',
          dataPic: []
        }
      }

      const clearPicBranch = {
        type: 'clearPayload',
        data: {
          clientSelected: {},
          branchSelected: {},
          dataPic: []
        }
      }

      this.$store.commit('client/setPayloadAddBranch', clearBranch)
      this.$store.commit('client/setPayloadAddPicBranch', clearPicBranch)
    },
    detailBranch (branchId, data) {
      this.$router.push(`/am/vendors/${branchId}/store`)
    },
    getListBranch (props) {
      if (!props || !props.dontLoading) {
        this.isLoading = true
      }

      const payload = {
        url: this.url,
        vendor_id: this.$route.params.vendorId
      }

      if (this.$route.query.area_id) {
        payload.area_id = this.$route.query.area_id
      }

      this.$store
        .dispatch('client/getListBranch', payload)
        .then(response => {
          const res = response.data.data
          this.listBranch = res

          const clientSelected = {
            company_name: res.company_name,
            name: res.name,
            logo: res.logo,
            id: this.$route.params.vendorId
          }

          if (res.code) {
            clientSelected.code = res.code
          }

          this.dataClient = {
            name: response.data.data.name,
            company_name: response.data.data.company_name,
            logo: response.data.data.logo,
            client_type_code: response.data.data.client_type_code,
            client_type_name: response.data.data.client_type_name
          }

          // Tambah response detail client
          this.$store.commit('client/setPayloadAddBranch', {
            type: 'clientSelected',
            clientSelected: clientSelected
          })
          this.$store.commit('client/setPayloadAddPicBranch', {
            type: 'clientSelected',
            clientSelected: clientSelected
          })
          this.$store.commit('client/setDetailClient', { data: clientSelected })
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    },
    getListAreaKoordinator () {
      this.isLoadingAreaC = true
      const payload = {
        url: this.url,
        vendor_id: this.$route.params.vendorId,
        area_id: this.$route.query.area_id,
        role_id: AREACOORD
      }

      this.$store
        .dispatch('client/getUserRoleSMRC', payload)
        .then(response => {
          const res = response.data.data
          this.initAreaKoordinator = JSON.parse(JSON.stringify(res))
          this.dataAreaKoordinator = res
          this.isLoadingAreaC = false
        })
        .catch(error => {
          alertErrorMessage(error)
          this.isLoadingAreaC = false
        })
    },
    handleAddBranch () {
      this.showSidebar = true
      this.$store.commit('client/setAddBranchFromList', true)
    },
    handleCloseSidebar () {
      this.showSidebar = false
    },
    handleCloseSidebarEdit () {
      this.showSidebarEdit = false
      this.selectedBranch = null
    },
    handleEditBranch (data) {
      this.showSidebarEdit = true
      this.selectedBranch = data
    },
    handleAddAreaCoordinator () {
      this.showSidebarCoordinator = true
    },
    handleCloseSidebarAreaCoordinator () {
      this.showSidebarCoordinator = false
    },
    updateSearchValue (value) {
      if (this.tabActive === 'unit') {
        this.searchUnit = value
      } else {
        this.searchAreaK = value
      }
    },
    filterAreaK () {
      const filter = this.initAreaKoordinator.filter(item => {
        return item.name
          .toLowerCase()
          .includes(this.searchAreaK.toLowerCase())
      })

      this.dataAreaKoordinator = filter
    }
  },
  created () {
    this.$store.commit('client/setPayloadAddPicBranch', {
      type: 'branchSelected',
      branchSelected: {}
    })
    this.getListBranch()
    this.getListAreaKoordinator()
  },
  watch: {
    searchUnit (newValue) {
      if (newValue === '') {
        this.getListBranch({ dontLoading: true })
      }
    },
    searchPic (newValue) {
      if (newValue === '') {
        this.dataPicFilter = {
          branch: this.initListStore.branch,
          pics: this.initListStore.pics
        }
      }
    }
  }
}
</script>

<style scoped>
.col-1 {
  background: linear-gradient(90deg, #ed4949 0%, #a40707 100%);
  border-radius: 20px;
  color: white;
}

.wave {
  object-fit: cover;
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  border-radius: 20px;
}

.wrapper-vendor {
  background: #f7f7f7;
  border-radius: 18px;
}

.border-right {
  border-right: 2px #eaeaea solid;
}

.btn-detail {
  border-radius: 20px;
  color: #eb4600;
  background: white;
  border: 1px solid #eb4600;
  padding-top: 14px;
  padding-bottom: 14px;
  font-family: "Averta-Bold";
  font-size: 16px;
  width: 100%;
}

.list-branch {
  height: 245px;
}

.branch-name {
  height: 60px;
}

.branch-name p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
