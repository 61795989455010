<template>
  <div v-if="dataPenawaranRejected && dataPenawaranRejected.length">
    <div v-for="data, index in dataPenawaranRejected" :key="index">
      <div class="columns mt-5 card is-block mb-4 border-card border-radius-10">
        <div class="wrapper-header p-4">
          <p class="averta-bold">
            Reject ke-{{ index + 1 }} | <span class="text-grey">{{ convertDate(data.created_at) }}</span>
          </p>
        </div>
        <div class="wrapper-body p-4">
          <div class="is-flex is-justify-content-space-between">
            <div class="body-left">
              <div class="is-flex is-align-items-start mb-4">
                <ChatIcon class="mr-3 mt-1" />
                <div>
                  <p class="averta-bold">Alasan Reject</p>
                  <p class="has-text-grey">{{ data.reject_reason }}</p>
                </div>
              </div>
              <div class="is-flex is-align-items-start mb-4">
                <ChatIcon class="mr-3 mt-1" />
                <div>
                  <p class="averta-bold">Keterangan Reject</p>
                  <p class="has-text-grey">{{ data.reject_note }}</p>
                </div>
              </div>
            </div>
            <div class="body-right">
              <p v-if="isBS" class="has-text-grey has-text-right">Grand Total</p>
              <p v-if="isBS" class="averta-bold has-text-right mb-3">{{ formatThousand(data.grand_total) }}</p>
              <button class="btn-detail-penawaran is-clickable" @click="showDetailReject(data)">Lihat Detail
                Penawaran</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="modalDetailReject" :can-cancel="false" width="90%">
      <div class="modal-wrapper" v-if="dataModal">
        <div class="header-modal is-flex is-justify-content-space-between is-align-items-center">
          <div class="is-flex is-align-items-center">
            <BagIcon class="icon-bag mr-2" />
            <span class="averta-bold label-20">
              Riwayat Penawaran
            </span>
          </div>
          <div class="is-clickable" @click="modalDetailReject = false">
            <b-icon icon="close"></b-icon>
          </div>
        </div>
        <div class="body-modal history-offering">
          <p class="averta-bold mb-4">Informasi Reject Penawaran</p>
          <div class="columns">
            <div class="column">
              <p class="has-text-grey">Alasan Reject</p>
              <p class="averta-bold">{{ dataModal.reject_reason }}</p>
            </div>
            <div class="column">
              <p class="has-text-grey">Tanggal Reject</p>
              <p class="averta-bold">{{ convertDate(dataModal.created_at) }}</p>
            </div>
            <div class="column">
              <p class="has-text-grey">Keterangan Reject</p>
              <p class="averta-bold">{{ dataModal.reject_note }}</p>
            </div>
          </div>
          <p class="averta-bold mb-4">Detail Histori Penawaran</p>
          <div class="notification is-light is-info has-text-dark">
            <div class="columns">
              <div class="column">
                <p class="has-text-grey">Durasi Pengerjaan</p>
                <p class="averta-bold">{{ dataModal.duration || '-' }}</p>
              </div>
              <div class="column">
                <p class="has-text-grey">Total Tukang</p>
                <p class="averta-bold">{{ dataModal.total_worker || '-' }}</p>
              </div>
              <div v-if="isBS" class="column">
                <p class="has-text-grey">Grand Total</p>
                <p class="averta-bold has-text-danger">{{ formatThousand(dataModal.grand_total) }}</p>
              </div>
            </div>
          </div>
          <div>
            <DetailPenawaranRejected :dataPenawaranRejected="dataModal" :isDataInModal="true" :isBs="isBS" class="wrap-detail-rejected"/>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
  <div v-else class="is-flex is-justify-content-center">
    <div class="has-text-centered">
      <ProyekEmptyIcon />
      <p class="averta-bold">Tidak Ada Penawaran Rejected</p>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import { ProyekEmptyIcon, ChatIcon, BagIcon } from '../icons'
import { formatCurrency } from '@/helpers'
import DetailPenawaranRejected from '@/components/project/DetailPenawaranRejected'

export default {
  name: 'TabDetailPenawaranRejected',
  components: {
    ProyekEmptyIcon,
    ChatIcon,
    BagIcon,
    DetailPenawaranRejected
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      dataPenawaranRejected: 'project/getDataDetailPenawaranRejected',
      dataPenawaran: 'project/getDataDetailPenawaran'
    }),
    isBS () {
      if (this.user.roles[0].name === 'Account Manager' || this.user.roles[0].name === 'Business Support') {
        return true
      } return false
    }
  },
  data () {
    return {
      modalDetailReject: false,
      area: null,
      dataModal: null
    }
  },
  methods: {
    formatThousand (x) {
      return formatCurrency(x)
    },
    convertDate (date) {
      return moment(date).format('DD MMMM YYYY')
    },
    showDetailReject (data) {
      this.dataModal = data
      // this.area = data.area_components[0].area_name
      this.modalDetailReject = true
    },
    changeIsActive (areaName) {
      this.area = areaName
    }
  }
}
</script>

<style scoped>
.text-grey {
  color: #868686;
  font-family: 'Averta';
}

.wrapper-header {
  background: #FAFAFA;
  border-radius: 12px 12px 0px 0px;
}

.sub-title {
  color: #868686;
}

.btn-detail-penawaran {
  border: 1px solid #D9272D;
  font-family: 'Averta-Bold';
  background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.25%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  border-radius: 20px;
  padding: 12px 18px;
}

.modal-wrapper {
  border-radius: 20px;
}

.header-modal {
  background: #911a1e;
  padding: 24px;
  color: white;
  border-radius: 12px 12px 0px 0px;
}

.body-modal {
  background: white;
  padding: 12px 24px;
  border-radius: 0px 0px 12px 12px;
}

.body-modal.history-offering {
  height: 70vh;
  overflow-y: auto;
}

.icon-bag {
  width: 20px;
}

.btn-tabs {
  height: auto;
  border-radius: 100px;
  color: #9a9a9a;
  border: 1px solid #e1e1e1;
}

.btn-tabs.is-active {
  background: rgba(49, 133, 252, 0.1);
  color: #3185fc;
  border: 1px solid #3185fc;
  font-family: 'Averta-Bold';
}

.bg-grey {
  background: #FAFAFA !important;
}

.text-note {
  color: #868686;
}

.wrapper-item-pekerjaan {
  border-radius: 12px;
}

.wrapper-volume {
  background: #ffffff;
  padding: 12px 24px;
  border: 1px solid #E1E1E1;
  border-radius: 10px;
}

.section-volume {
  padding-right: 28px;
}

.section-satuan {
  border-left: 2px solid #E1E1E1;
  padding-left: 14px;
  padding-right: 120px;
}

.wrapper-tukang,
.wrapper-materail {
  border-radius: 10px;
}

.wrapper-item-tukang:not(:last-child),
.wrapper-item-material:not(:last-child),
.wrapper-item-variant:not(:last-child) {
  padding-bottom: 24px;
  border-bottom: 1px solid #E1E1E1;
}

.skill-img {
  width: 34px;
  margin-right: 10px;
}

.divider {
  border-top: 2px dashed #E1E1E1;
  margin: 32px 0px;
}

.text-hidden {
  background: #3185FC;
  font-family: 'Averta';
  font-style: italic;
  color: white;
  padding: 4px 14px;
  border-radius: 14px;
}

.text-custom {
  background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-style: italic;
}

.note-material {
  border: 1px solid #E1E1E1;
  border-radius: 12px;
  padding: 10px;
}

.wrap-detail-rejected {
  scroll-padding-top: 200px;
}
</style>
