<template>
    <DetailSkeleton v-if="isLoading" class="p-6" />
    <div class="py-6 container container-detail" v-else>
        <!-- Header -->
        <div class="is-flex is-justify-content-space-between is-align-items-center mb-5">
            <div class="is-flex is-align-items-center is-clickable btn-back-wrapper" @click="goBack">
                <ArrowLeftIcon class="mr-3" />
                <p class="label-18 averta-bold">Kembali ke list</p>
            </div>
            <RedirectClient v-if="dataHeader && dataHeader.vendor" :vendor="dataHeader.vendor"/>
        </div>
        <!-- Header -->

        <!-- STICKY HEADER -->
        <div class="sticky-header" :style="{ top: navbarPosition }">
          <div class="is-flex is-align-items-center ml-sm-1">
            <div class="is-flex is-align-items-center is-clickable" @click="goBack">
              <ArrowLeftIcon class="mr-3" />
            </div>
            <div>
              <div class="is-flex is-align-items-center flex-sm-column align-items-sm-start">
                <span class="mr-3 averta-bold label-18">{{ conditionalIDProject }} | {{ dataHeader.store.name }}</span>
                <div class="is-flex is-align-items-center">
                  <div class="label-12 px-4 py-2 status-survey" :class="getClassName(dataHeader.status.status_name)">
                    <span>{{ wordingHelper(dataHeader.status.status_name) }}</span>
                  </div>
                  <div v-if="dataHeader.is_reschedule === 1 || dataHeader.is_extend === 1" class="is-flex is-align-items-center">
                    <div v-if="dataHeader.is_reschedule === 1" class="wrap-tag-reschedule-extends is-flex is-align-items-center is-justify-content-center">
                      <span>Reschedule</span>
                    </div>
                    <div v-if="dataHeader.is_extend === 1" class="wrap-tag-reschedule-extends is-flex is-align-items-center is-justify-content-center">
                      <span>Extend</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Status Proyek -->
        <div class="mb-5">
            <p class="label-14">ID Proyek</p>
            <div class="is-flex is-align-items-center">
                <p class="label-28 averta-bold mr-3">{{ conditionalIDProject }} | {{ dataHeader.store.name }}</p>
                <div class="is-flex is-align-items-center">
                  <div class="label-12 px-4 py-2 status-survey" :class="getClassName(dataHeader.status.status_name)">
                      <span>{{ wordingHelper(dataHeader.status.status_name) }}</span>
                  </div>
                  <div v-if="dataHeader.is_reschedule === 1 || dataHeader.is_extend === 1" class="is-flex is-align-items-center">
                    <div v-if="dataHeader.is_reschedule === 1" class="wrap-tag-reschedule-extends is-flex is-align-items-center is-justify-content-center">
                      <span>Reschedule</span>
                    </div>
                    <div v-if="dataHeader.is_extend === 1" class="wrap-tag-reschedule-extends is-flex is-align-items-center is-justify-content-center">
                      <span>Extend</span>
                    </div>
                  </div>
                </div>
            </div>
            <!-- Tag Complaint -->
            <div v-if="dataHeader.project_complain && dataHeader.project_complain.total_project_complain > 0 && dataHeader.project_complain.type === 'parent'" class="tag-complaint is-flex is-align-items-center is-justify-content-center my-1">
              <p class="averta-bold label-12 white-space-nowrap">Komplain</p>
            </div>
            <!-- Tag Complaint -->
            <!-- Tag Redirect -->
            <div
              v-if="dataHeader.project_complain && dataHeader.project_complain.type === 'child'"
              class="tag-redirect is-flex is-align-items-center is-justify-content-center is-disabled"
            >
              <TagRedirect class="mr-3" color="#868686"/>
              <p class="mr-3">
                <span class="averta-bold label-16 text-grey">ID Terkait : </span>
                <span class="averta-bold label-16 text-grey">{{ dataHeader.project_complain.survey_parent_id }}</span>
              </p>
              <ArrowRightIcon color="#E10009" width="13" height="13" v-if="isBS || isSM" />
            </div>
            <!-- Tag Redirect -->
            <p class="label-14">Tanggal Mulai : <span class="averta-bold">{{ convertDate(dataHeader.created_at) }}</span></p>
        </div>
        <!-- Status Proyek -->

        <!-- Group ID Survey & Proyek -->
        <SurveyProjectID :dataHeaderProps="dataHeader" @onModalProyekDarurat="handleProyekDarurat($event)" />

        <!-- Tabs -->
        <div>
            <b-tabs>
                <b-tab-item :label="`Daftar Material (${dataHeader.material.total_list_material})`">
                    <TabDaftarMaterial :data="daftarMaterial" @refreshData="refreshData()"/>
                </b-tab-item>
                <b-tab-item :label="`Material Diproses (${dataHeader.material.total_material_in_process})`">
                    <TabMaterialProcess :data="materialProcess" @getMaterialProcess="getMaterialProcess()" @getDaftarMaterial="getDaftarMaterial()"/>
                </b-tab-item>
                <!-- <b-tab-item :label="'Daftar Biaya'">
                    Lorem ipsum dolor sit amet.
                </b-tab-item> -->
                <b-tab-item :label="'Informasi Proyek'">
                    <TabInformasiProyek :data="informasiProyek" />
                </b-tab-item>
                <b-tab-item label="Catatan Proyek">
                  <ProjectNote :dataProjectNoteProps="dataProjectNote" :surveyId="dataHeader.survey_id" :isFromProject="true" :loadData="getDataProjectNote" />
                  <PersonalNote :notes="dataProjectNote.personal_note" :isFromProject="true" :loadData="getDataProjectNote" :surveyId="dataHeader.survey_id"/>
                </b-tab-item>
            </b-tabs>
        </div>
        <!-- Tabs -->

        <ModalProyekDarurat :dataModalUrgent="dataHeader.project_urgent_id" :surveyId="dataHeader.id" :showModal="modalProyekDarurat" :loadData="getProjectHeader" :width="isMobile ? '95vw' : '45vw'" @closeModal="handleProyekDarurat(false)" />
    </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import { ArrowLeftIcon, TagRedirect } from '@/components/icons'
import RedirectClient from '@/components/RedirectClient'
import DetailSkeleton from '@/components/skeleton/DetailSkeleton'
import TabDaftarMaterial from './TabDaftarMaterial.vue'
import TabMaterialProcess from './TabMaterialProcess.vue'
import TabInformasiProyek from './TabInformasiProyek.vue'
import { getStatusClassName, wordingMenuHelper } from '@/helpers'
import SurveyProjectID from '@/components/project/detail/SurveyProjectID.vue'
import ModalProyekDarurat from '@/components/modal/ModalProyekDarurat.vue'
import ProjectNote from '@/components/project/detail/ProjectNote.vue'
import PersonalNote from '@/components/project/detail/PersonalNote.vue'

export default {
  name: 'DetailProjectPurchasing',
  components: {
    ArrowLeftIcon,
    TagRedirect,
    RedirectClient,
    TabDaftarMaterial,
    TabMaterialProcess,
    TabInformasiProyek,
    DetailSkeleton,
    SurveyProjectID,
    ModalProyekDarurat,
    ProjectNote,
    PersonalNote
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      heightNavbar: 'dashboard/getNavbarHeight'
    }),
    isMobile () {
      if (window.innerWidth <= 768) {
        return true
      }
      return false
    },
    conditionalIDProject () {
      if (this.dataHeader.project_complain && this.dataHeader.project_complain.survey_complain_code) {
        return this.dataHeader.project_complain.survey_complain_code
      } else {
        return this.dataHeader.survey_id
      }
    }
  },
  data () {
    return {
      isLoading: false,
      dataHeader: null,
      daftarMaterial: null,
      materialProcess: null,
      informasiProyek: null,
      modalProyekDarurat: false,
      dataProjectNote: null,
      navbarPosition: null
    }
  },
  methods: {
    getProjectHeader () {
      this.isLoading = true

      const payload = {
        url: this.url,
        proyek_id: this.$route.params.projectId
      }

      this.$store
        .dispatch('projectV2/getProjectHeader', payload)
        .then((response) => {
          this.dataHeader = response.data.data
          this.isLoading = false
        })
        .catch((error) => {
          console.log(error)
          this.isLoading = false
          this.$buefy.toast.open({
            message: 'Gagal memuat data header, silahkan coba lagi',
            type: 'is-danger'
          })
        })
    },
    getDaftarMaterial () {
      const payload = {
        url: this.url,
        proyek_id: this.$route.params.projectId,
        category: 'unprocessed-materials'
      }

      this.$store
        .dispatch('projectV2/getDetailProject', payload)
        .then((response) => {
          this.daftarMaterial = response.data.data
        })
        .catch((error) => {
          console.log(error)
          this.$buefy.toast.open({
            message: 'Gagal memuat data daftar material, silahkan coba lagi',
            type: 'is-danger'
          })
        })
    },
    getMaterialProcess () {
      const payload = {
        url: this.url,
        proyek_id: this.$route.params.projectId,
        category: 'processed-materials'
      }

      this.$store
        .dispatch('projectV2/getDetailProject', payload)
        .then((response) => {
          this.materialProcess = response.data.data
        })
        .catch((error) => {
          console.log(error)
          this.$buefy.toast.open({
            message: 'Gagal memuat data material proses, silahkan coba lagi',
            type: 'is-danger'
          })
        })
    },
    getInformasiProyek () {
      this.isLoading = true

      const payload = {
        url: this.url,
        proyek_id: this.$route.params.projectId,
        category: 'information'
      }

      this.$store
        .dispatch('projectV2/getDetailProject', payload)
        .then((response) => {
          this.informasiProyek = response.data.data
          this.$store.commit('project/setDataDetailPenawaran', {
            area_component: response.data.data.area_component,
            duration: null,
            project_date: null
          })
          this.isLoading = false
        })
        .catch((error) => {
          console.log(error)
          this.isLoading = false
          this.$buefy.toast.open({
            message: 'Gagal memuat data informasi proyek, silahkan coba lagi',
            type: 'is-danger'
          })
        })
    },
    getDataProjectNote () {
      const payload = {
        url: this.url,
        proyek_id: this.$route.params.projectId,
        category: 'note'
      }
      this.$store
        .dispatch('projectV2/getDetailProject', payload)
        .then((response) => {
          this.dataProjectNote = response.data.data
        })
        .catch((error) => {
          console.log(error)
          this.$buefy.toast.open({
            message:
              'Gagal memuat data material, silahkan coba lagi',
            type: 'is-danger'
          })
        })
    },
    getClassName (status) {
      return getStatusClassName(status)
    },
    wordingMenu (value, subValue) {
      return wordingMenuHelper(value, subValue)
    },
    convertDate (value) {
      return moment(value).format('DD MMMM YYYY')
    },
    refreshData () {
      this.getProjectHeader()
      this.getDaftarMaterial()
      this.getMaterialProcess()
    },
    goBack () {
      const queryParams = this.$route.query

      if (queryParams && queryParams.fromSearch) {
        this.$router.go(-1)
      } else {
        this.$router.push('/purchasing/project')
      }
    },
    handleScroll () {
      const el = document.querySelector('.sticky-header')

      if (el) {
        if (window.scrollY > 200) {
          el.classList.add('is-pinned')
          setTimeout(() => {
            el.classList.add('transition')
          }, 1)
        } else {
          el.classList.remove('is-pinned')
          el.classList.remove('transition')
        }
      }
    },
    handleProyekDarurat (props = {}) {
      const { value } = props
      this.modalProyekDarurat = value
    },
    wordingHelper (value) {
      return wordingMenuHelper(value)
    },
    adjustStickyPosition () {
      setTimeout(() => {
        if (this.heightNavbar) {
          this.navbarPosition = this.heightNavbar + 'px'
        }
      }, 600)
    }
  },
  watch: {
    heightNavbar (val) {
      if (val) {
        this.adjustStickyPosition()
      }
    }
  },
  mounted () {
    this.getDataProjectNote()
    window.addEventListener('scroll', this.handleScroll)
    this.adjustStickyPosition()
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  created () {
    this.getProjectHeader()
    this.getDaftarMaterial()
    this.getMaterialProcess()
    this.getInformasiProyek()
  }
}
</script>

<style scoped>

.wrap-tag-reschedule-extends {
  background: #E1EEFF;
  border: 1px solid #3185FC;
  padding: 3px 10px;
  border-radius: 100px;
  margin-left: 8px;
}

.wrap-tag-reschedule-extends span {
  color: #3185FC;
  font-size: 14px;
  font-style: italic;
}

.tag-complaint {
  width: 90px;
  height: 30px;
  padding: 2px 8px;
  border-radius: 100px;
  background: #E10009;
  color: white;
}

.tag-redirect {
  background: #FAFAFA;
  width: max-content;
  height: 36px;
  border-radius: 100px;
  border: 1px solid #E1E1E1;
  padding: 8px 12px;
}

.tag-redirect.is-disabled {
  cursor: not-allowed;
}

</style>
