<template>
  <SidebarClientTemplate
    :show="showSidebar"
    :disabledSubmit="disabledSubmit"
    @closeSidebar="showModalCancelFunc"
    @submit="handleSubmit"
  >
    <template v-slot:bodySidebar>
      <b-loading
        :is-full-page="true"
        v-model="isLoadingSubmit"
        :can-cancel="false"
      >
        <SpinnerIcon />
      </b-loading>
      <div>
        <p class="averta-black label-20 mb-4">Tambah {{renderTitle}}</p>

        <ClientAndBranchInfo
          :detailClient="payloadAddPicBranch.clientSelected"
          :detailBranch="payloadAddPicBranch.branchSelected"
          :detailStore="isAdmin ? getDetailStore.selectedStore : null"
        />

        <AdminInfo v-if="isAdmin" currentTab="pic" typeSection="add" />
        <HeadEInfo v-else-if="isHead" currentTab="pic" typeSection="add" />
        <AreaKoordinatorInfo v-else-if="isAreaKoordinator" currentTab="pic" typeSection="add" />
        <BranchInfo v-else currentTab="pic" typeSection="add" />
      </div>

      <!-- Modal Cancel -->
      <ModalConfirm
        :showModal="showModalCancel"
        imageProps="sunset.png"
        :titleProps="`Batalkan Penambahan ${renderTitle}?`"
        descProps="Jika data sudah diisi maka akan dihapus oleh sistem"
        imageClass="mb-0"
      >
        <template v-slot:button>
          <div class="is-flex is-align-items-center mt-4">
            <ButtonApp
              :isSecondary="true"
              :is-bold="true"
              width="185px"
              height="52px"
              class="mr-3"
              @click="showModalCancel = false"
            >
              Kembali
            </ButtonApp>
            <ButtonApp :is-bold="true" width="185px" height="52px" @click="handleCloseSidebar">
              Batalkan Perubahan
            </ButtonApp>
          </div>
        </template>
      </ModalConfirm>

      <!-- Modal Submit -->
      <ModalConfirm
        :showModal="showModalConfirm"
        imageProps="data-not-found.png"
        :imageClass="type === 'head' ? 'mt-3 mb-5' : ''"
        titleProps="Semua Data Yang Dimasukkan Sudah Benar?"
        descProps="Pastikan kamu sudah melakukan pengecekkan sebelum dikirim"
        :descClass="type === 'head' ? 'mb-2' : ''"
      >
        <template v-slot:additional v-if="type === 'head'">
          <ul class="wrap-desc mb-5">
            <li class="has-text-left">
              <p class="is-inline averta-bold label-14">
                {{ titlePusat }} akan menerima email untuk ubah password sebelum masuk ke dashboard B2B Kanggo
              </p>
            </li>
          </ul>
        </template>
        <template v-slot:button>
          <div class="is-flex is-align-items-center">
            <ButtonApp
              :isSecondary="true"
              :is-bold="true"
              width="185px"
              height="52px"
              class="mr-3"
              @click="cancelClose"
            >
              Batalkan
            </ButtonApp>
            <ButtonApp
              :is-bold="true"
              width="185px"
              height="52px"
              @click="confirmModal"
            >
              Simpan
            </ButtonApp>
          </div>
        </template>
      </ModalConfirm>
    </template>
  </SidebarClientTemplate>
</template>

<script>
// Helpers
import { mapGetters } from 'vuex'
import { SpinnerIcon } from '@/components/icons'
// eslint-disable-next-line no-unused-vars
import { validationPayloadHelper, alertErrorMessage, toastMessage, titlePICBranch, titlePusat } from '@/helpers'
import {
  HEAD_ENGINEERING_SMRC,
  ADMIN_SMRC,
  AREA_KOORDINATOR_SMRC,
  HEADOFENG,
  AREACOORD,
  ADM
} from '@/helpers/constants'

// Components
import ButtonApp from '@/components/button/ButtonApp.vue'
import ModalConfirm from '../ModalConfirm.vue'

import AdminInfo from '../admin-components/AdminInfo.vue'
import BranchInfo from './BranchInfo.vue'
import HeadEInfo from '../head-components/HeadEInfo.vue'
import AreaKoordinatorInfo from '../area-koordinator-components/AreaKoordinatorInfo.vue'

import ClientAndBranchInfo from '../store-components/ClientAndBranchInfo.vue'
import SidebarClientTemplate from '@/components/sidebar/SidebarClientTemplate.vue'

export default {
  name: 'SidebarAddPICBranch',
  components: {
    SpinnerIcon,

    AdminInfo,
    BranchInfo,
    HeadEInfo,
    AreaKoordinatorInfo,

    ButtonApp,
    ModalConfirm,
    ClientAndBranchInfo,
    SidebarClientTemplate
  },
  props: {
    type: {
      type: String // admin | head | area-koordinator
    },
    show: {
      type: Boolean
    },
    close: {
      type: Function
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      payloadAddPicBranch: 'client/getPayloadAddPicBranch',
      getDetailStore: 'client/getPayloadStore'
    }),
    getCode () {
      const temp = this.payloadAddPicBranch.clientSelected.code
      return temp || ''
    },
    isAdmin () {
      return this.type === 'admin'
    },
    isHead () {
      return this.type === 'head'
    },
    isAreaKoordinator () {
      return this.type === 'area-koordinator'
    },
    renderTitle () {
      let temp
      switch (this.type) {
        case 'admin':
          temp = ADMIN_SMRC
          break

        case 'head':
          temp = HEAD_ENGINEERING_SMRC
          break

        case 'area-koordinator':
          temp = AREA_KOORDINATOR_SMRC
          break

        default:
          temp = titlePICBranch(this.getCode)
          break
      }

      return temp
    },
    titlePusat () {
      return titlePusat()
    }
  },
  data () {
    return {
      showSidebar: false,
      showModalConfirm: false,
      showModalCancel: false,
      isLoadingSubmit: false,
      disabledSubmit: true,
      name: '',
      companyName: '',
      logo: ''
    }
  },
  methods: {
    cancelClose () {
      this.showModalConfirm = false
    },
    showModalCancelFunc () {
      const temp = this.payloadAddPicBranch.dataPic

      const isFilled = temp.every(item =>
        Object.values(item).some(value => value && value.trim() !== '')
      )
      if (temp.length > 0 && isFilled) {
        this.showModalCancel = true
      } else {
        this.handleCloseSidebar()
      }
    },
    confirmModal () {
      this.isLoadingSubmit = true
      this.showModalConfirm = false

      const payload = {
        url: this.url,
        data: {
          vendor_id: this.payloadAddPicBranch.clientSelected.id,
          users: this.payloadAddPicBranch.dataPic
        }
      }

      if (this.type === 'admin') {
        payload.data.branch_id = this.$route.params.storeId // need to fix property name, must be store_id
      } else if (this.type !== 'head') {
        payload.data.branch_id = this.payloadAddPicBranch.branchSelected.id
      }

      // eslint-disable-next-line no-unused-vars
      let temp
      switch (this.type) {
        case 'admin':
          delete payload.data.vendor_id
          payload.data.role_id = +ADM
          temp = 'createRoleSMRC'
          break

        case 'head':
          payload.data.vendor_id = this.$route.params.vendorId
          payload.data.role_id = +HEADOFENG
          temp = 'createRoleSMRC'
          break

        case 'area-koordinator':
          delete payload.data.vendor_id
          payload.data.role_id = +AREACOORD
          payload.data.area_id = this.$route.query.area_id
          temp = 'createRoleSMRC'
          break

        // PIC Cabang & Chief Engineering
        default:
          temp = 'createBranch'
          break
      }

      this.$store
        .dispatch(`client/${temp}`, payload)
        .then(() => {
          toastMessage(`${this.renderTitle} Berhasil Di Buat`)
          this.handleCloseSidebar()
          this.$emit('reloadData', { refresh: true, type: this.type })
          this.isLoadingSubmit = false

          setTimeout(() => {
            window.scrollTo({
              top: document.body.scrollHeight,
              behavior: 'smooth'
            })
          }, 800)
        })
        .catch(error => {
          alertErrorMessage(error)
          this.isLoadingSubmit = false
        })
    },
    handleSubmit () {
      this.showModalConfirm = true
    },
    handleCloseSidebar () {
      this.$store.commit('client/setPayloadAddPicBranch', {
        type: 'dataPic',
        dataPic: [
          {
            name: '',
            email: '',
            phone: ''
          }
        ]
      })
      this.showSidebar = false
      this.showModalCancel = false
      this.close()
    }
  },
  watch: {
    show (value) {
      this.showSidebar = value
    },
    payloadAddPicBranch: {
      handler (value) {
        this.disabledSubmit = validationPayloadHelper(value, 'pic', this.type)
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.wrap-desc {
  background: #fafafa;
  border: 1px solid #e1e1e1;
  padding: 20px 30px;
  list-style-type: disc;
}
</style>
