<template>
  <ClientSkeleton v-if="isLoading || isLoadingUnit" class="px-6 pb-6 pt-5" />
  <div v-else class="c-w-100">
    <b-loading
      v-if="isLoadingSubmit || isLoadingUnit || isLoadingKoordinator || isLoadingAdmin"
      :is-full-page="true"
      :active="true"
      :can-cancel="false"
    >
      <SpinnerIcon />
    </b-loading>
    <div class="container is-fluid pt-5 pb-6 px-6 min-h-90vh">
      <div class="is-flex is-justify-content-space-between mb-5">
        <div class="is-flex is-align-items-center is-clickable" @click="goBack()">
          <ArrowLeftIcon class="mr-3" />
          <p class="averta-bold is-size-5">Kembali</p>
        </div>
      </div>

      <div style="gap: 1rem" class="is-flex is-flex-direction-row is-justify-content-space-between mb-5">

        <div class="c-w-100 notification is-light border-radius-20 border-2">
        <!-- <div class="c-w-100 notification is-light border-radius-20 border-2" :class="{'mb-0 c-w-50' : isSMRC}"> -->
          <p class="averta-bold label-18">{{ renderTitleStore }}</p>
          <p class="averta-bold color-red mt-2 label-14">
            {{ dataStore.detail.phone && dataStore.detail.phone !== '?' ? dataStore.detail.phone : '-' }}
          </p>

          <div class="is-flex">
            <p class="label-14 mr-2">{{ dataStore.detail.address }}</p>
            <div class="is-clickable"
              @click="showModalEditAddress"
            >
              <img :src="require(`@/assets/img/icon-edit-${dataStore.detail.address ? 'merah' : 'abu'}.png`)" width="20">
            </div>
          </div>
        </div>

        <!-- SMRCN -->
        <!-- <div style="gap: 1rem" v-if="isSMRC" class="notification is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-flex-start is-light border-radius-20 border-2 c-w-50">
          <img src="@/assets/img/circelcard.svg"  alt="wave" class="wave" />
          <div class="is-flex is-flex-direction-column is-justify-content-flex-start is-align-items-flex-start">
            <SaldoCoinIcon size="50" />
          </div>
          <div>
            <p class="label-20">Saldo</p>
            <p class="label-28 averta-black">{{formatCurrency(100000000)}}</p>
            <p class="label-14 averta-regular-italic">Terakhir Update: {{formatDate('2024-11-30')}}</p>
          </div>
        </div> -->
        <!-- SMRCN -->
      </div>

      <div v-if="isSMRC" class="flex-center-vertical mb-5">
        <div class="btn-tabs-2 mr-3" @click="handleClickTab('unit')" :class="{'is-active': tabActive === 'unit'}">
          <p>Daftar No. Unit</p>
        </div>
        <div class="btn-tabs-2 mr-3" @click="handleClickTab('coordinatorEngineering')" :class="{'is-active': tabActive === 'coordinatorEngineering'}">
          <p>Daftar {{renderKoordinator}}</p>
        </div>
        <div class="btn-tabs-2" @click="handleClickTab('admin')" :class="{'is-active': tabActive === 'admin'}">
          <p>Daftar {{renderAdmin}}</p>
        </div>
      </div>

      <div style="min-height:60px;" class="is-flex is-justify-content-space-between is-align-items-center mb-4">
        <p v-if="isSMRC && tabActive === 'unit'"
        class="is-flex is-flex-direction-column is-justify-content-center is-align-flex-start averta-bold is-size-5">
          Daftar No. Unit {{dataStore.detail.name}}
          <span class="averta-regular label-14 mr-1">
            <b>{{ dataNoUnit.length }}</b>
            Unit tersedia
          </span>
        </p>

        <p class="is-flex is-flex-direction-column is-justify-content-center is-align-flex-start averta-bold is-size-5"
           v-if="isSMRC && tabActive === 'coordinatorEngineering'">
            Daftar {{renderKoordinator}}
           <span class="averta-regular label-14 mr-1">
            <b>{{ dataStore.total_pic }}</b> Koordinator Engineering tersedia
           </span>
        </p>

        <p v-if="isSMRC && tabActive === 'admin'"
        class="is-flex is-flex-direction-column is-justify-content-center is-align-flex-start averta-bold is-size-5">
          Daftar {{renderAdmin}}
          <span class="averta-regular label-14 mr-1">
            <b>{{ dataAdmin.length }}</b>
            Admin tersedia
          </span>
        </p>
        <div v-if="!isSMRC">
          <p class=" averta-bold is-size-5"> Daftar PIC </p>

          <p>
            <span class="averta-bold label-14 mr-1">{{ dataStore.total_pic }}</span>
            <span class="color-grey">PIC Tersedia</span>
          </p>
        </div>
        <div  class="flex-center">
          <b-field class="mb-0 input-custom-1">
            <b-input
              placeholder="Cari..."
              type="search"
              icon="magnify"
              :value="getSearchValue"
              @input="updateSearchValue"
              @icon-click="getFilterDetailStore"
              @keyup.native.enter="getFilterDetailStore"
            />
          </b-field>
          <ButtonApp @click="handleAddPICStore" class="ml-5" v-if="tabActive === 'coordinatorEngineering'">
            <p class="averta-bold color-white py-2 px-5">+ Tambah {{renderKoordinator}}</p>
          </ButtonApp>
          <ButtonApp @click="handleAddAdmin" class="ml-5" v-if="tabActive === 'admin'">
            <p class="averta-bold color-white py-2 px-5">+ Tambah {{renderAdmin}}</p>
          </ButtonApp>
        </div>
      </div>

      <TabListNoUnitStore
        v-if="tabActive === 'unit'"
        :data="dataNoUnit"
        :refreshData="getDetailStore"
      />

      <TabListKoordinatorEngineering
        v-if="tabActive === 'coordinatorEngineering'"
        :dataUser="dataKoordinator"
        :refreshData="getDetailStore"
        @handleEditPIC="handleEditPIC($event)"
        @handleDeletePIC="handleDeletePIC($event)"
      />

      <TabListAdmin
        v-if="tabActive === 'admin'"
        :data="dataAdmin"
        :refreshData="getKoordinatorAndAdmin"
      />

      <!-- Delete PIC -->
      <ModalConfirm
        :showModal="showModalDelete"
        imageProps="sunset.png"
        imageClass="mb-3"
        :titleProps="`Hapus PIC ${detailUser && detailUser.name}?`"
        descProps="Pastikan kamu sudah lakukan pengecekkan sebelum menghapus data ini."
      >
        <template v-slot:button>
          <div class="is-flex">
            <ButtonApp
              @click="handleCancelDeletePIC"
              class="mr-3"
              :isSecondary="true"
              width="185px"
              height="52px"
            >
              <p class="averta-bold">Kembali</p>
            </ButtonApp>
            <ButtonApp
              @click="handleConfirmDeletePIC"
              width="185px"
              height="52px"
            >
              <p class="averta-bold">Hapus</p>
            </ButtonApp>
          </div>
        </template>
      </ModalConfirm>

      <SidebarEditPICStore
        :show="showSidebar"
        :close="handleCloseSidebar"
        :selectedPIC="typeSidebar === 'edit' ? selectedPIC : null"
        :refreshData="refreshDetailStore"
        :typeSidebar="typeSidebar"
      />

      <SidebarAddPICBranch
        type="admin"
        :show="sidebarAddAdmin"
        :close="handleCloseAdmin"
        @reloadData="getKoordinatorAndAdmin($event)"
      />

      <ModalEditAddress
        :dataAddress="dataStore.detail"
        :showModal="showEditAddress"
        @closeModal="closeModalEditAddress"
        @confirmChangeAddress="handleChangeAddress($event)"
        @showMaps="handleShowMaps"
      />

      <ModalMapsAddress
        :showModal="modalAddAddressByMap"
        @closeModal="closeModalAddAddressByMap"
        @selectAddress="handleSelectAddress($event)"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// eslint-disable-next-line no-unused-vars
import { alertErrorMessage, formatDateHelper, formatCurrency, isSMRC, titlePICStore, decryptData } from '@/helpers'
import { PICTK, ADM, ADMIN_SMRC } from '@/helpers/constants'

import { ArrowLeftIcon, TrashIcon, SpinnerIcon, SaldoCoinIcon } from '@/components/icons'
import ClientSkeleton from '@/components/skeleton/ClientSkeleton'
import ButtonApp from '@/components/button/ButtonApp.vue'
import ModalConfirm from './components/ModalConfirm.vue'

import SidebarEditPICStore from './components/store-components/SidebarEditPICStore.vue'
import SidebarAddPICBranch from './components/branch-components/SidebarAddPICBranch.vue'
import TabListNoUnitStore from './components/store-components/TabListNoUnitStore.vue'
import TabListAdmin from './components/store-components/TabListAdmin.vue'
import TabListKoordinatorEngineering from './components/store-components/TabListKoordinatorEngineering.vue'

export default {
  name: 'ListDetailStore',
  components: {
    ArrowLeftIcon,
    ButtonApp,
    // eslint-disable-next-line vue/no-unused-components
    TrashIcon,
    SpinnerIcon,
    // eslint-disable-next-line vue/no-unused-components
    SaldoCoinIcon,
    ClientSkeleton,

    ModalConfirm,
    SidebarEditPICStore,
    TabListNoUnitStore,
    TabListAdmin,
    TabListKoordinatorEngineering,
    SidebarAddPICBranch,

    ModalEditAddress: () => import('./components/store-components/EditAddressStore.vue'),
    ModalMapsAddress: () => import('./components/ModalMapsAddress.vue')
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      dataMaps: 'client/getDataMaps'
    }),
    isSMRC () {
      return isSMRC(this.dataStore.vendor.code)
    },
    renderTitleStore () {
      const name = this.dataStore.detail.name
      return this.isSMRC ? name : 'Toko ' + name
    },
    renderKoordinator () {
      return titlePICStore(this.dataStore.vendor.code)
    },
    renderAdmin () {
      return ADMIN_SMRC
    },
    getSearchValue () {
      if (this.tabActive === 'unit') {
        return this.searchUnit
      } else if (this.tabActive === 'coordinatorEngineering') {
        return this.searchKoordinator
      } else {
        return this.searchAdmin
      }
    }
  },
  data () {
    return {
      isLoading: false,
      isLoadingSubmit: false,
      isLoadingUnit: false,
      isLoadingKoordinator: false,
      isLoadingAdmin: false,

      searchUnit: null,
      searchKoordinator: null,
      searchAdmin: null,

      showModalDelete: false,
      showSidebar: false,
      showEditAddress: false,
      sidebarAddAdmin: false,

      typeSidebar: 'add', // add | edit
      tabActive: 'unit', // unit | coordinatorEngineering | admin
      dataStore: null,
      selectedPIC: null,
      modalAddAddressByMap: false,
      detailUser: null,

      dataAdmin: [],
      dataKoordinator: [],
      dataNoUnit: [],

      initUnit: [],
      initKoordinator: [],
      initAdmin: []
    }
  },
  methods: {
    formatDate (date) {
      return formatDateHelper({ date })
    },
    formatCurrency (value) {
      return formatCurrency(value)
    },
    handleClickTab (tab) {
      this.tabActive = tab
    },
    goBack () {
      this.$router.go(-1)
    },
    refreshDetailStore () {
      this.getDetailStore()
      this.getKoordinatorAndAdmin({ type: 'koordinator' })
    },
    getDetailStore () {
      this.showSidebar = false
      this.showEditAddress = false
      this.isLoading = true
      const payload = {
        url: this.url,
        store_id: this.$route.params.storeId
      }

      this.$store
        .dispatch('client/getDetailStore', payload)
        .then(response => {
          const res = response.data.data
          this.dataStore = res
          this.isLoading = false
          this.$store.commit('client/setPayloadStore', { selectedStore: res.detail })
          this.$store.commit('client/setDetailClient', { data: res.vendor })
          this.$store.commit('client/setDetailBranch', { data: res.branch })
          this.$store.commit('client/setPayloadAddPicBranch', {
            type: 'branchSelected',
            branchSelected: res.branch
          })
          this.$store.commit('client/setPayloadAddPicBranch', {
            type: 'clientSelected',
            clientSelected: res.vendor
          })

          // Change this latter, vendor_code === 'SMRCN'
          if (!this.isSMRC) {
            this.tabActive = 'coordinatorEngineering'
          }

          // this.initKoordinator = JSON.parse(JSON.stringify(res.pics))
          this.getListUnit()
        })
        .catch(error => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    },
    getFilterDetailStore () {
      if (this.tabActive === 'unit') {
        const filter = this.initUnit.filter(item => {
          return item.name
            .toLowerCase()
            .includes(this.searchUnit.toLowerCase())
        })

        this.dataNoUnit = filter
      } else if (this.tabActive === 'coordinatorEngineering') {
        const filter = this.initKoordinator.filter(item => {
          return item.name
            .toLowerCase()
            .includes(this.searchKoordinator.toLowerCase())
        })

        this.dataKoordinator = filter
      } else if (this.tabActive === 'admin') {
        const filter = this.initAdmin.filter(item => {
          return item.name
            .toLowerCase()
            .includes(this.searchAdmin.toLowerCase())
        })

        this.dataAdmin = filter
      }

      // this.isLoading = true

      // const payload = {
      //   url: this.url,
      //   store_id: this.$route.params.storeId,
      //   search: this.search
      // }

      // this.$store
      //   .dispatch('client/getDetailStore', payload)
      //   .then(response => {
      //     this.dataStore = response.data.data
      //     this.isLoading = false

      //     // Change this latter, vendor_code === 'SMRCN'
      //     if (!this.isSMRC) {
      //       this.tabActive = 'coordinatorEngineering'
      //     }
      //   })
      //   .catch(error => {
      //     this.isLoading = false
      //     alertErrorMessage(error)
      //   })
    },
    getListUnit () {
      this.isLoadingUnit = true
      const payload = {
        url: this.url,
        branch_id: this.$route.params.storeId
      }

      this.$store
        .dispatch('client/getUnitsSMRC', payload)
        .then(response => {
          const res = response.data.data
          this.dataNoUnit = res
          this.initUnit = JSON.parse(JSON.stringify(res))
          this.isLoadingUnit = false
        })
        .catch(error => {
          this.isLoadingUnit = false
          alertErrorMessage(error)
        })
    },
    getKoordinatorAndAdmin (props) {
      const { type } = props
      const payload = {
        url: this.url,
        // branch_id: decryptData(this.$route.query.source)
        store_id: this.$route.params.storeId
      }

      if (type === 'koordinator') {
        this.isLoadingKoordinator = true
        payload.role_id = +PICTK
      } else if (type === 'admin') {
        this.isLoadingAdmin = true
        payload.role_id = +ADM
      }

      this.$store
        .dispatch('client/getUserRoleSMRC', payload)
        .then(response => {
          const res = response.data.data

          if (type === 'koordinator') {
            this.dataKoordinator = res
            this.initKoordinator = JSON.parse(JSON.stringify(res))
          } else if (type === 'admin') {
            this.dataAdmin = res
            // console.log(this.dataAdmin.length)
            this.initAdmin = JSON.parse(JSON.stringify(res))
          }
          this.isLoadingKoordinator = false
          this.isLoadingAdmin = false
        })
        .catch(error => {
          alertErrorMessage(error)
          this.isLoadingKoordinator = false
          this.isLoadingAdmin = false
        })
    },
    handleAddPICStore () {
      this.showSidebar = true
      this.typeSidebar = 'add'
    },
    handleCancelDeletePIC () {
      this.showModalDelete = false
    },
    handleDeletePIC (data) {
      this.detailUser = data
      this.showModalDelete = true
    },
    handleConfirmDeletePIC () {
      this.isLoadingSubmit = true
      const payload = {
        url: this.url,
        store_id: this.$route.params.storeId,
        user_id: this.detailUser.id
      }

      this.$store
        .dispatch('client/deletePICStore', payload)
        .then(() => {
          this.showModalDelete = false
          this.isLoadingSubmit = false
          this.$buefy.toast.open({
            message: `<p class="averta-bold">${this.detailUser.name} Berhasil Di Hapus</p>`,
            type: 'is-success'
          })
          this.detailUser = null
          this.getDetailStore()
        })
        .catch(error => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    },
    handleEditPIC (data) {
      this.showSidebar = !this.showSidebar
      this.selectedPIC = data
      this.typeSidebar = 'edit'
    },
    handleCloseSidebar () {
      this.showSidebar = false
    },
    showModalEditAddress () {
      this.showEditAddress = !this.showEditAddress
    },
    closeModalEditAddress () {
      this.showEditAddress = false
    },
    handleChangeAddress () {
      this.isLoadingSubmit = true
      const res = this.dataStore.detail

      const tempMap = this.dataMaps && this.dataMaps.data
      const tempCurrentPlace = this.dataMaps && this.dataMaps.currentPlace

      const payload = {
        url: this.url,
        store_id: this.$route.params.storeId,
        data: {
          address: tempMap ? tempMap.address : res.address,
          detail_address: res.detail_address
        }
      }

      if (tempCurrentPlace && tempCurrentPlace.lat) {
        payload.data.latitude = tempCurrentPlace.lat.toString()
        payload.data.longitude = tempCurrentPlace.lng.toString()
      }

      this.$store
        .dispatch('client/updateAddressStore', payload)
        .then(() => {
          this.$buefy.toast.open({
            message: '<p class="averta-bold">Alamat Berhasil Di Ubah</p>',
            type: 'is-success'
          })
          this.getDetailStore()
          this.isLoadingSubmit = false
          this.$store.commit('client/setDataMaps', { data: null, currentPlace: null })
        })
        .catch(error => {
          this.isLoadingSubmit = false
          alertErrorMessage(error)
        })
    },
    handleShowMaps () {
      this.modalAddAddressByMap = !this.modalAddAddressByMap
    },
    closeModalAddAddressByMap () {
      this.modalAddAddressByMap = false
    },
    handleSelectAddress (data) {
      const res = data.data
      this.dataStore.detail.latitude = res.latitude || res.lat || data.currentPlace.lat
      this.dataStore.detail.longitude = res.longitude || res.lon || data.currentPlace.lng
    },
    handleAddAdmin () {
      this.sidebarAddAdmin = true
    },
    handleCloseAdmin () {
      this.sidebarAddAdmin = false
      // this.$store.commit('client/setClearPayloadStore')
    },
    updateSearchValue (value) {
      if (this.tabActive === 'unit') {
        this.searchUnit = value
      } else if (this.tabActive === 'coordinatorEngineering') {
        this.searchKoordinator = value
      } else {
        this.searchAdmin = value
      }
    }

  },
  created () {
    this.getDetailStore()
    this.getListUnit()
    this.getKoordinatorAndAdmin({ type: 'admin' })
    this.getKoordinatorAndAdmin({ type: 'koordinator' })
  },
  watch: {
    searchUnit (val) {
      if (val === '') {
        this.getFilterDetailStore()
      }
    },
    searchKoordinator (val) {
      if (val === '') {
        this.getFilterDetailStore()
      }
    },
    searchAdmin (val) {
      if (val === '') {
        this.getFilterDetailStore()
      }
    }
  }
}

</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.wave {
  object-fit: cover;
  position: absolute;
  height: 100%;
  width: auto;
  bottom: 0;
  right: 0;
  border-radius: 20px;
}

.wrapper-vendor {
  background: #F7F7F7;
  border-radius: 8px;
}

.border-right {
  border-right: 2px #EAEAEA solid;
}

.btn-detail {
  border-radius: 20px;
  color: #EB4600;
  background: white;
  border: 1px solid #EB4600;
  padding-top: 14px;
  padding-bottom: 14px;
  font-family: 'Averta-Bold';
  font-size: 16px;
}

</style>
