<template>
  <div>
    <p class="averta-black label-20">Daftar Surat Perintah Kerja (SPK)</p>
    <p class="mb-5">Dokumen ini mencantumkan nilai penawaran untuk proyek yang akan dilakukan</p>

    <table v-if="dataSPK.length > 0" class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
      <tr class="bg-dark-grey-5 has-text-white has-text-centered tr-wrapper">
        <td class="py-4">
          <p class="averta-bold">NO</p>
        </td>
        <td class="py-4">
          <p class="averta-bold">NAMA DOKUMEN</p>
        </td>
        <td class="py-4">
          <p class="averta-bold">UNIT</p>
        </td>
        <td class="py-4">
          <p class="averta-bold">DIUNGGAH TANGGAL</p>
        </td>
        <td class="py-4">
          <p class="averta-bold">CATATAN</p>
        </td>
        <td class="py-4">
          <p class="averta-bold">SALDO AWAL</p>
        </td>
        <td class="py-4">
          <p class="averta-bold">SISA SALDO</p>
        </td>
        <td class="py-4"></td>
      </tr>

      <tr v-for="(item, index) in dataSPK" :key="index" class="vertical-middle tr-wrapper">
        <td class="py-3">
          <p class="averta-bold has-text-centered">{{ formatThousand(index+1) }}</p>
        </td>
        <td class="py-3">
          <p class="averta-bold">{{ item.name }}</p>
        </td>
        <td class="py-3">
          <p class="averta-bold">{{ item.branch_name }}</p>
        </td>
        <td class="py-3">
          <div class="flex-center is-flex-direction-column">
            <p class="has-text-centered averta-bold">{{ formatDate(item.created_at) }}</p>
            <p class="has-text-centered">{{ item.created_by }}</p>
          </div>
        </td>
        <td class="py-3">
          <p class="averta-bold">{{ item.note || '-' }}</p>
        </td>
        <td class="py-3">
          <div class="averta-bold is-flex is-justify-content-space-between">
            <p>Rp</p>
            <p>{{item.contract_balance === null ? '0' : formatThousand(item.contract_balance) }}</p>
          </div>
        </td>
        <td class="py-3">
          <div class="averta-bold is-flex is-justify-content-space-between">
            <p>Rp</p>
            <p>{{item.current_balance === 0 ? '0' : formatThousand(item.current_balance) }}</p>
          </div>
        </td>
        <td>
          <div class="is-relative">
            <div class="flex-center">
              <div class="is-clickable mr-2 p-3" @click="handleOpenSPK(item.id)">
                <HamburgerDotIcon />
              </div>
            </div>
            <div
              style="gap: 0.25rem"
              v-if="dropdownId === item.id"
              class="openModalsAction"
            >
              <div
                @click="openModalHistory(item.id)"
                style="padding: 15px; height: 55px"
                class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center c-w-100 hoverable"
              >
                <div
                  style="gap: 0.5rem"
                  class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center"
                >
                  <HistoryIcon color="#252525" size="25" />
                  <h5 style="font-size: 16px" class="averta-light">
                    Lihat Riwayat Penggunaan Saldo
                  </h5>
                </div>
                <div
                  class="is-flex is-flex-direction-row is-justify-content-flex-end is-align-items-center"
                >
                  <ChevronIcon size="25" />
                </div>
              </div>

              <hr
                style="border: 0.5px solid #e0e0e0; margin: 0 auto; height: 1px"
                class="c-w-90"
              />

              <div
                @click="openModalPreview(item.url)"
                style="padding: 15px; height: 55px"
                class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center c-w-100 hoverable"
              >
                <div
                  style="gap: 0.5rem"
                  class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center"
                >
                  <DocumentIcon color="#252525" size="25" />
                  <h5 style="font-size: 16px" class="averta-light">Lihat Lampiran SPK</h5>
                </div>
                <div
                  class="is-flex is-flex-direction-row is-justify-content-flex-end is-align-items-center"
                >
                  <ChevronIcon size="25" />
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </table>

    <div v-else class="column c-w-100 has-text-centered">
      <ProyekEmptyIcon />
      <p class="averta-bold mt-2">
        Data Tidak Ditemukan
      </p>
    </div>

    <ModalPreviewImage
      :isActive="modalPreview"
      :urlOneImage="urlImagePreview"
      @closeModal="closeModalPreview"
    />

    <ModalHistorySPK
      :showModal="showModalHistory"
      :documentId="dropdownId"
      @closeModal="closeModalHistory"
    />

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  HamburgerDotIcon,
  TrashIcon,
  ProyekEmptyIcon,
  HistoryIcon,
  ChevronIcon,
  DocumentIcon
} from '@/components/icons'
import { formatDateHelper, formatCurrency } from '@/helpers'
import ModalPreviewImage from '@/components/modal/ModalPreviewImage.vue'
import ModalHistorySPK from './ModalHistorySPK.vue'

export default {
  name: 'TableListSPK',
  props: {
    dataSPK: {
      type: Array
    }
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    TrashIcon,
    HamburgerDotIcon,
    ProyekEmptyIcon,
    HistoryIcon,
    ChevronIcon,
    DocumentIcon,
    ModalPreviewImage,
    ModalHistorySPK
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      getDetailClient: 'client/getDetailClient'
    })
  },
  data () {
    return {
      dropdownId: null,
      showModalHistory: false,
      modalPreview: false,
      urlImagePreview: ''
    }
  },
  methods: {
    formatDate (date) {
      return formatDateHelper({ date })
    },
    formatThousand (val) {
      return formatCurrency(val, true)
    },
    handleOpenSPK (id) {
      this.dropdownId = this.dropdownId === id ? false : id
    },
    openModalHistory (id) {
      this.showModalHistory = true
    },
    openModalPreview (url) {
      this.modalPreview = true
      this.urlImagePreview = url
    },
    closeModalPreview () {
      this.modalPreview = false
      this.urlImagePreview = ''
      this.dropdownId = null
    },
    closeModalHistory () {
      this.showModalHistory = false
      this.dropdownId = null
    }
  },
  mounted () {
  }
}
</script>

<style scoped>
.openModalsAction {
  position: absolute;
  z-index: 9999;
  top: 100%;
  right: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 25rem;
  height: auto;
  margin: 0 auto;
  padding: 5px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 6px 24px 0px #2318181a;
  border: 1px solid #e1e1e1;
  overflow: hidden;

}
.hoverable:hover {
  border-radius: 15px;
  background: #e0e0e0;
  cursor: pointer;
}
table {
  overflow-x: auto;
  width: 100%;
  table-layout: fixed;
  position: sticky;
}
td:first-child, th:first-child {
  left: 0;
  width: 5%;
  min-width: 5%;
  max-width: 5%;
}
td:nth-child(2), th:nth-child(2),
td:nth-child(3), th:nth-child(3),
td:nth-child(4), th:nth-child(4),
td:nth-child(5), th:nth-child(5),
td:nth-child(6), th:nth-child(6),
td:nth-child(7), th:nth-child(7) {
  width: 15%;
  min-width: 15%;
  max-width: 15%;
  overflow: hidden;
}
td:last-child, th:last-child {
  right: 0;
  width: 5%;
  min-width: 5%;
  max-width: 5%;
}
</style>
