/* eslint-disable no-unused-vars */
import moment from 'moment'
import { AES, enc } from 'crypto-js'
import { ToastProgrammatic as Toast, SnackbarProgrammatic as Snackbar } from 'buefy'
import {
  SUMMARECON_MTOWN,
  BRANCH,
  STORE,
  PIC_BRANCH,
  PIC_STORE,
  BRANCH_SMRC,
  STORE_SMRC,
  PIC_BRANCH_SMRC,
  PIC_STORE_SMRC,
  ADMIN_SMRC,
  HEAD_ENGINEERING_SMRC,
  AREA_KOORDINATOR_SMRC
} from '@/helpers/constants'

const KEY_LOCAL_STORAGE = process.env.VUE_APP_SECRET_KEY

export function encryptData (data) {
  const encryptDatas = AES.encrypt(JSON.stringify(data), KEY_LOCAL_STORAGE).toString()
  return encryptDatas
}

export function decryptData (data) {
  const decryptDatas = AES.decrypt(data, KEY_LOCAL_STORAGE).toString(enc.Utf8)
  return decryptDatas
}

export function formatCurrency (value, hideCurrency) {
  if (!value) return '-'
  let option

  if (!Number.isInteger(value)) {
    option = {
      maximumFractionDigits: 2
    }
  } else {
    option = {
      maximumFractionDigits: 0
    }
  }

  if (!hideCurrency) {
    option.currency = 'IDR'
    option.style = 'currency'
  }

  const currency = new Intl.NumberFormat('id-ID', option).format(value)
  return currency
}

function handleTag (tag, id, role) {
  if (tag === 'survey' || tag === 'offering') {
    if (role === 'purchasing/') {
      return `/${role}project/offering/${id}/detail`
    }
    return `/${role}project/survey/${id}/detail`
  } else if (tag === 'report_follow_up') {
    return `/${role}report/follow-up`
  } else {
    return `/${role}project/${id}/detail`
  }
}

export function navigateTo (tag, id, role) {
  if (role === 'Konsultan Survey') {
    return handleTag(tag, id, '')
  } else if (role === 'Site Manager') {
    return handleTag(tag, id, 'sm/')
  } else if (role === 'Account Manager') {
    return handleTag(tag, id, 'am/')
  } else if (role === 'Purchasing') {
    return handleTag(tag, id, 'purchasing/')
  }
}

export function getStatusClassName (status) {
  switch (status) {
    case 'Survei Diproses':
    case 'Survey Diproses':
    case 'Penawaran Ditinjau':
    case 'Penawaran Ditinjau Client':
    case 'Survey Terjadwal':
    case 'Survey Terinput':
    case 'Survey Diminta':
    case 'waiting-confirmation':
    case 'Menunggu Konfirmasi':
    case 'Menunggu Konfirmasi Client':
    case 'Penawaran Terkirim':
      return 'bg-yellow'

    case 'Survei Berlangsung':
    case 'Survey Berlangsung':
    case 'Survey Dilakukan':
    case 'Proyek Aktif':
    case 'Proyek Berjalan':
    case 'Pekerjaan Dilakukan':
    case 'Pekerjaan Selesai':
    case 'Konfirmasi Pekerjaan Selesai':
      return 'bg-blue'

    case 'Survei Selesai':
    case 'Survey Selesai':
      return 'bg-purple-1'

    case 'Menunggu Penawaran':
      return 'bg-orange'

    case 'Penawaran Rejected':
    case 'Penawaran Ditolak':
    case 'Proses Site Manager':
    case 'Proses SM':
      return 'bg-red'

    case 'Penawaran Disetujui':
    case 'Penawaran Disetujui Client':
    case 'Proses Business Support':
    case 'Persiapan Material Purchasing':
    case 'Proses BS':
    case 'SPK SIK Diterima':
    case 'Spk Sik Diterima':
    case 'Proyek Dibuat':
    case 'waiting-approval':
      return 'bg-green'

    case 'Direncanakan':
    case 'Proyek Direncanakan':
    case 'Proses Purchasing':
    case 'Assign Tukang':
      return 'bg-dark-yellow'

    case 'Menunggu Pembayaran':
    case 'payment-waiting':
    case 'payment-pending':
      return 'status-survey-dark-red'

    case 'Pembayaran Gagal':
    case 'payment-failed':
      return 'bg-purple-2'

    case 'Pembayaran Berhasil':
    case 'payment-success':
      return 'bg-dark-green'

    case 'Proyek Lunas':
    case 'Terbayar':
      return 'bg-dark-grey'

    case 'Survei Dibatalkan':
    case 'Survey Dibatalkan':
    case 'Direview AM':
    case 'Invoice Terkirim':
      return 'bg-chocolate'

    default:
      break
  }
}

export function getStatusClass (status) {
  switch (status) {
    case 'Survei Diproses':
    case 'Menunggu Konfirmasi':
    case 'Penawaran Ditinjau':
      return 'status-survey-yellow'
    case 'Survei Berlangsung':
      return 'status-survey-blue'
    case 'Survei Selesai':
      return 'status-survey-purple'
    case 'Penawaran Disetujui':
      return 'status-survey-green'
    case 'Penawaran Ditolak':
      return 'status-survey-red'
    default:
      return 'status-survey-yellow'
  }
}

export async function handleCopyText (text) {
  try {
    await navigator.clipboard.writeText(text)
    Toast.open({
      message:
        '<p class="averta-bold">Tersalin!</p>',
      type: 'is-success',
      duration: 500
    })
  } catch ($e) {
    Toast.open({
      message:
        'Gagal, silahkan coba lagi!',
      type: 'is-danger'
    })
  }
}

export function alertErrorMessage (error, duration) {
  console.log(error)
  Snackbar.open({
    message: `<p class="averta-regular">${(error && error.response && error.response.data.message[0].message) || (error && error.response && error.response.data.message) || (error && error.response && error.response.data[0].message) || error || 'Gagal memuat data, silahkan coba lagi'}</p>`,
    duration: duration || 2000,
    position: 'is-top',
    type: 'is-danger'
  })
}
export function toastMessage (message) {
  Toast.open({
    duration: 2500,
    message: `<div style="max-width: 300px;"><p class="averta-regular">${message}</p</div>`,
    type: 'is-success'
  })
}

export function numberingList (limit, page, index) {
  return limit * (page - 1) + (index + 1)
}

// Tab, Subtab, Tag
export function wordingMenuHelper (value) {
  if (value === 'Menunggu Konfirmasi' || value === 'Penawaran Ditinjau' || value === 'waiting') {
    return 'Menunggu Konfirmasi Client'
  } else if (value === 'Penawaran Pending' || value === 'pending') {
    return 'Penawaran Terkirim'
  } else if (value === 'Penawaran Rejected' || value === 'rejected') {
    return 'Penawaran Ditolak'
  } else if (value === 'Penawaran Disetujui' || value === 'Penawaran Approved' || value === 'approved') {
    return 'Penawaran Disetujui Client'
  } else if (value === 'Proyek Aktif' || value === 'active') {
    return 'Proyek Berjalan'
  } else if (value === 'Proyek In-Planning' || value === 'Direncanakan' || value === 'in-planning') {
    return 'Proyek Direncanakan'
  } else if (value === 'payment-success') {
    return 'Pembayaran Berhasil'
  } else if (value === 'payment-failed') {
    return 'Pembayaran Gagal'
  } else if (value === 'payment-waiting' || value === 'payment-pending') {
    return 'Menunggu Pembayaran'
  }
  return value
}

export function formatSessionWorker (props = {}) {
  const { status, isForBackground, isStandFor } = props
  switch (status) {
    case 'Morning':
    case 'Pagi':
      return isStandFor ? 'P' : isForBackground ? 'bg-morning-session' : 'Pagi'
    case 'Siang':
    case 'Sore':
      return isStandFor ? 'S' : isForBackground ? 'bg-afternoon-session' : status // (Siang/Sore) -> Need discussion with egar
    case 'Night':
    case 'Malam':
      return isStandFor ? 'M' : isForBackground ? 'bg-night-session' : 'Malam'
    case 'Half Day':
      return isStandFor ? 'H' : isForBackground ? 'bg-half-day-session' : 'Half Day'
    case 'Full Day':
      return isStandFor ? 'F' : isForBackground ? 'bg-full-day-session' : 'Full Day'
    case 'Booking':
      return isStandFor ? 'B' : isForBackground ? 'bg-booking-session' : 'Booking'
    case 'Sesi 2':
      return isStandFor ? 'S2' : isForBackground ? 'bg-session-2' : 'Sesi 2'
    case 'Sesi 4':
      return isStandFor ? 'S4' : isForBackground ? 'bg-session-4' : 'Sesi 4'
    default:
      return isStandFor ? status : isForBackground ? 'bg-white' : status
  }
}

export function sesiStatusInformation () {
  const sesi = [
    {
      type: 'Half Day'
    },
    {
      type: 'Pagi'
    },
    {
      type: 'Sore'
    },
    {
      type: 'Malam'
    },
    {
      type: 'Full Day'
    },
    {
      type: 'Booking'
    },
    {
      type: 'Sesi 2'
    },
    {
      type: 'Sesi 4'
    }
  ]
  return sesi
}

export function isEmptyAfterTrim (str) {
  if (!str) {
    return true
  }
  return str.trim() === ''
}

export function formatDateHelper (props = {}) {
  const { date, format } = props
  if (!date) return '-'

  const initFormat = format || 'DD MMMM YYYY'
  return moment(date).format(initFormat)
}

export function compareBetweenDates (props = {}) {
  const { compare = new Date(), start, end } = props
  const compareDate = moment(compare)
  const startDate = moment(start)
  const endDate = moment(end)

  return compareDate.isBetween(startDate, endDate)
}

export function copywritingRole (roleName) {
  switch (roleName) {
    case 'Account Manager':
      return 'Business Support'

    default:
      return roleName
  }
}

export function titleReportProgress (status) {
  switch (status) {
    case 'Survey Terjadwal':
      return 'Survey Terinput'

    default:
      return status
  }
}

export function isEmptyOrInvalid (value, obj) {
  if (!value) {
    return true
  }

  // Check if value is empty or only contains whitespace
  if ((typeof value === 'string' && value.trim() === '') || (value.length && value.length < 1)) {
    return true
  }

  // Check if the email is valid (basic email regex pattern)
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  if (value === obj.email && !emailPattern.test(value)) {
    return true
  }

  return false
}

export function formatRoleTitle (string) {
  const updatedString = string.includes('Account Manager') ? string.replace('Account Manager', 'Business Support') : string
  return updatedString
}

export function validationPayloadHelper (value, currentTab, type) {
  const data = value

  function checkClientSelected () {
    if (
      data.clientSelected === null ||
      typeof data.clientSelected !== 'object' ||
      Object.keys(data.clientSelected).length === 0
    ) {
      return true
    } else {
      return false
    }
  }

  function checkBranch () {
    // skip Head of Engineering & Area Koordinator
    if (type === 'head' || type === 'area-koordinator') {
      return false
    } else if (currentTab === 'branch') {
      if (data.branchName === '') {
        return true
      }
    } else if (currentTab === 'pic') {
      if (
        data.branchSelected === null ||
        typeof data.branchSelected !== 'object' ||
        Object.keys(data.branchSelected).length === 0
      ) {
        return true
      }
    }
  }

  function checkDataPic () {
    let result = false
    if (data.dataPic.length > 0) {
      data.dataPic.forEach((item, index) => {
        if (item.name === '') {
          result = true
        } else if (item.email === '') {
          result = true
        } else if (item.phone === '') {
          result = true
        }
      })
    }

    return result
  }

  return checkClientSelected() || checkBranch() || checkDataPic()
}

export function validationClientAndBranch (value) {
  const checkClient = Object.keys(value.clientSelected).length > 0
  const checkBranch = Object.keys(value.branchSelected).length > 0
  if (checkClient && checkBranch) {
    return false
  } else {
    return true
  }
}

export function Terbilang (angka) {
  const bilangan = ['', 'Satu', 'Dua', 'Tiga', 'Empat', 'Lima', 'Enam', 'Tujuh', 'Delapan', 'Sembilan', 'Sepuluh', 'Sebelas']

  function konversi (angka) {
    if (angka < 12) {
      return bilangan[angka]
    } else if (angka < 20) {
      return bilangan[angka - 10] + ' Belas'
    } else if (angka < 100) {
      return bilangan[Math.floor(angka / 10)] + ' Puluh ' + bilangan[angka % 10]
    } else if (angka < 200) {
      return 'Seratus ' + konversi(angka - 100)
    } else if (angka < 1000) {
      return bilangan[Math.floor(angka / 100)] + ' Ratus ' + konversi(angka % 100)
    } else if (angka < 2000) {
      return 'Seribu ' + konversi(angka - 1000)
    } else if (angka < 1000000) {
      return konversi(Math.floor(angka / 1000)) + ' Ribu ' + konversi(angka % 1000)
    } else if (angka < 1000000000) {
      return konversi(Math.floor(angka / 1000000)) + ' Juta ' + konversi(angka % 1000000)
    } else if (angka < 1000000000000) {
      return konversi(Math.floor(angka / 1000000000)) + ' Miliar ' + konversi(angka % 1000000000)
    } else if (angka < 1000000000000000) {
      return konversi(Math.floor(angka / 1000000000000)) + ' Triliun ' + konversi(angka % 1000000000000)
    }
  }

  if (angka < 1) {
    return 'Nol Rupiah'
  }

  return konversi(angka) + ' Rupiah'
}

export function debounce (fn, delay) {
  var timeoutID = null
  return function () {
    clearTimeout(timeoutID)
    var args = arguments
    var that = this
    timeoutID = setTimeout(function () {
      fn.apply(that, args)
    }, delay)
  }
}

export function isObjectsSimilar (obj1, obj2) {
  if (JSON.stringify(obj1) === JSON.stringify(obj2)) return true
  return false
}

export function scrollToElementHelper (el) {
  const element = document.querySelector(el)
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' })
  }
}

export function isSMRC (code) {
  if (!code) return false
  return code === SUMMARECON_MTOWN
}
export function titlePusat () {
  return HEAD_ENGINEERING_SMRC
}
export function titleBranch (code) {
  return isSMRC(code) ? BRANCH_SMRC : BRANCH
}
export function titlePICBranch (code) {
  return isSMRC(code) ? PIC_BRANCH_SMRC : PIC_BRANCH
}
export function titleStore (code) {
  return isSMRC(code) ? STORE_SMRC : STORE
}
export function titlePICStore (code) {
  return isSMRC(code) ? PIC_STORE_SMRC : PIC_STORE
}
