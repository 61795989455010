<template>
  <div v-if="listBranch.list.length > 0" class="columns is-flex is-flex-wrap-wrap">
    <div class="column is-3" v-for="(branch, index) in listBranch.list" :key="index">
      <div class="card border-radius-20 border-1-grey-2 list-branch p-4">
        <div class="is-flex is-justify-content-space-between">
          <div class="branch-name mb-4 c-w-55">
            <p class="averta-bold label-20">
              {{ `${renderTitleBranch} ${branch.name}` }}</p>
          </div>
          <div class="logo-unit" v-if="isSMRC">
            <img :src="branch.branch_logo" />
          </div>
        </div>

        <div class="wrapper-vendor p-3">
          <div class="is-flex is-justify-content-space-around">
            <div class="has-text-centered flex-1">
              <p>Jumlah {{ renderTitleStore }}</p>
              <p class="averta-bold">{{ branch.total_store }}</p>
            </div>
            <div class="border-right"></div>
            <div class="has-text-centered flex-1">
              <p>Jumlah PIC</p>
              <p class="averta-bold">{{ branch.total_pic }}</p>
            </div>
          </div>

          <div v-if="isSMRC">
            <hr class="dashed" />
            <div
              class="is-flex c-w-100 is-flex-direction-row is-justify-content-space-between is-align-items-center"
            >
              <div
                style="gap: 0.5rem"
                class="is-flex is-align-items-center is-justify-content-flex-end"
              >
                <SaldoCoinIcon size="35" />
                <p class="has-text-centered averta-bold">Saldo</p>
              </div>
              <div
                class="is-flex is-flex-direction-column is-align-items-flex-end is-justify-content-center"
              >
                <p class="has-text-centered averta-bold label-16">
                  {{ branch.balance === 0 ? 'Rp0' : formatCurrency(branch.balance) }}
                </p>
                <span v-if="branch.is_should_refill === 1" style="gap: 0.25rem" class="is-flex is-flex-direction-row is-align-items-center is-justify-content-center">
                  <AlertIcon />
                  <p style="font-size: 12px" class="averta-reguler color-red">
                    Segera Isi Ulang Saldo
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-5 is-flex is-justify-content-space-around">
          <ButtonApp
            height="45px"
            width="100%"
            class="mr-3"
            :is-bold="true"
            :is-secondary="true"
            @click="handleEditBranch(branch)"
            >Edit</ButtonApp
          >
          <ButtonApp
            height="45px"
            width="100%"
            :is-bold="true"
            @click="detailBranch(branch.id, branch)"
            >Lihat Detail</ButtonApp
          >
        </div>
      </div>
    </div>
  </div>
  <div v-else class="column c-w-100 has-text-centered">
    <ProyekEmptyIcon />
    <p class="averta-bold mt-2">
      Data Tidak Ditemukan
    </p>
  </div>
</template>

<script>
import { titleStore, formatCurrency } from '@/helpers'
import { ListDaftarBranchData } from '@/data/views/listbranchData'
import { SaldoCoinIcon, ProyekEmptyIcon } from '@/components/icons'
import { SUMMARECON_MTOWN, BRANCH } from '@/helpers/constants'
import ButtonApp from '@/components/button/ButtonApp.vue'

export default {
  name: 'TabListAreaBranch',
  props: {
    listBranch: {
      type: Object
    },
    edit: {
      type: Function
    },
    isSMRC: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ButtonApp,
    SaldoCoinIcon,
    ProyekEmptyIcon
    // AlertIcon
  },
  computed: {
    getListDaftarBranchData () {
      return ListDaftarBranchData(this.listBranch)
    },
    renderTitleStore () {
      return titleStore(this.isSMRC ? SUMMARECON_MTOWN : '')
    },
    renderTitleBranch () {
      return this.isSMRC ? '' : BRANCH
    }
  },
  data () {
    return {
      ListDaftarBranchData
    }
  },
  methods: {
    detailBranch (branchId) {
      this.$emit('detailBranch', branchId)
    },
    handleEditBranch (data) {
      this.$emit('handleEditBranch', data)
    },
    formatCurrency (value) {
      return formatCurrency(value)
    }
  }
}
</script>

<style scoped>
.col-1 {
  background: linear-gradient(90deg, #ed4949 0%, #a40707 100%);
  border-radius: 20px;
  color: white;
}

.wave {
  object-fit: cover;
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  border-radius: 20px;
}

.wrapper-vendor {
  background: #f7f7f7;
  border-radius: 18px;
}

.border-right {
  border-right: 2px #eaeaea solid;
}

.btn-detail {
  border-radius: 20px;
  color: #eb4600;
  background: white;
  border: 1px solid #eb4600;
  padding-top: 14px;
  padding-bottom: 14px;
  font-family: 'Averta-Bold';
  font-size: 16px;
  width: 100%;
}

.list-branch {
  min-height: 245px;
}

.branch-name {
  height: 60px;
}

.branch-name p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.logo-unit {
  height: 45px;
  max-width: 70px;
}

.logo-unit img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

.dashed {
  width: 100%;
  height: 0;
  max-height: 0;
  border: 1px dashed #e1e1e1;
}
</style>
