import axios from 'axios'
import moment from 'moment'

const state = () => ({
  cardReport: null,
  selectedFollowUp: null
})

const getters = {
  cardReport: (state, getters, rootState) => {
    return state.cardReport
  },
  getSelectedFollowUp: (state, getters, rootState) => {
    return state.selectedFollowUp
  }
}

const actions = {
  getCardReport ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_CARD_REPORT}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getListReport ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_LIST_REPORT}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getListReportFollowUp ({ commit, state }, payload) {
    let concatUrl = ''
    const { page, limit, search, vendor, status, startDate, endDate, orderBy, order } = payload

    if (page) {
      concatUrl += `page=${page}&`
    }
    if (limit) {
      concatUrl += `limit=${limit}&`
    }
    if (search) {
      concatUrl += `search=${search}&`
    }
    if (vendor) {
      concatUrl += `vendor=${vendor}&`
    }
    if (status) {
      concatUrl += `status=${status}&`
    }
    if (startDate) {
      concatUrl += `start_date=${startDate}&`
    }
    if (endDate) {
      concatUrl += `end_date=${endDate}&`
    }
    if (orderBy) {
      concatUrl += `order_by=${orderBy}&`
    }
    if (order) {
      concatUrl += `order=${order}&`
    }

    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_REPORT_FOLLOW_UP}${payload.isExport ? '/export' : ''}?${concatUrl}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  putReportFollowUp ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      const body = {
        date: payload.date
      }

      axios.put(`${payload.url}${process.env.VUE_APP_REPORT_FOLLOW_UP}/${payload.surveyId}/${payload.type}`, body)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getSimpleListVendor ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_REPORT_PROGRESS}/store`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getListReportProgress ({ commit, state }, payload) {
    let concatUrl = ''
    const { storeName, startDate, endDate } = payload

    const params = {}
    if (storeName) {
      params.store_name = storeName
    }
    if (startDate) {
      params.start_date = moment(startDate).format('YYYY-MM-DD')
    }
    if (endDate) {
      params.end_date = moment(endDate).format('YYYY-MM-DD')
    }

    for (const [key, value] of Object.entries(params)) {
      if (value) {
        concatUrl += `${key}=${value}&`
      }
    }

    if (concatUrl) {
      // Remove the trailing '&'
      concatUrl = concatUrl.slice(0, -1)
      concatUrl = `?${concatUrl}`
    }

    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_REPORT_PROGRESS}/table${concatUrl}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getTooltipInformation ({ commit, state }, payload) {
    const { surveyId, status, startDate, endDate } = payload
    const concatUrl = `?survey_id=${surveyId}&status=${status}&start_date=${startDate}&end_date=${endDate}`

    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_REPORT_PROGRESS}/info${concatUrl}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  postRejectConfirm ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${payload.url}${process.env.VUE_APP_REPORT_PROGRESS}/reject-confirm/${payload.surveyId}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  }
}

const mutations = {
  setCardReport (state, cardReport) {
    state.cardReport = cardReport
  },
  setSelectedFollowUp (state, value) {
    state.selectedFollowUp = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
