<template>
  <div v-if="dataHeader" class="mb-4 mt-sm-2">
    <div class="container-project-id bg-lightgray" id="containerProjectID">
      <div class="is-flex mb-1 overflow-auto">
        <div class="is-flex flex-sm-column border-right pr-5">
          <p class="label-sm-12">ID (Admin Panel) :</p>
          <p class="averta-black ml-2 mx-sm-0 label-sm-12">{{ dataHeader.survey_homecare_id ? `ID${dataHeader.survey_homecare_id}` : '-' }}</p>
        </div>
        <div class="is-flex flex-sm-column is-justify-content-flex-end px-5"
          :class="{ 'border-right': dataHeader.client_type_tag }">
          <p class="label-sm-12">ID Proyek (Admin Panel) :</p>
          <p class="averta-black ml-2 mx-sm-0 label-sm-12">{{ dataHeader.project_homecare_id ? `ID${dataHeader.project_homecare_id}` : '-' }}</p>
        </div>
        <div class="is-flex flex-sm-column is-justify-content-flex-end pl-5" v-if="dataHeader.client_type_tag">
          <p class="label-sm-12">Jenis Proyek :</p>
          <div class="is-flex">
            <p class="averta-black ml-2 mx-sm-0 label-sm-12 jenis-proyek">
              {{ dataHeader.client_type_tag }}
            </p>
          </div>
        </div>
      </div>
      <div class="is-flex flex-sm-column overflow-x-hidden">
        <p class="label-sm-12 title-id-proyek-darurat">ID Proyek Darurat ({{ dataUrgentID.total }}) :</p>
        <div class="is-flex c-w-sm-100">
          <div v-if="dataUrgentID.data.length > 0" class="is-flex ml-1 mx-sm-0">

            <p class="averta-black ml-1 item-project-urgent label-sm-12" v-for="(idProyek, index) in dataUrgentID.data" :key="`${index}-proyek-darurat`">
              ID{{ idProyek }}{{ index + 1 === dataUrgentID.data.length ? '' : ';' }}{{ index + 1 === dataUrgentID.data.length && dataUrgentID.remainData > 0 ? '...' : '' }}
            </p>

          </div>
          <p v-else class="is-italic color-red averta-bold ml-2 label-sm-12" :class="{'is-clickable': isBS }" @click="handleProyekDarurat(true, true)">Belum Diatur</p>
          <div class="is-flex is-align-items-center flex-shrink-0" :class="{'is-clickable more-wrapper': isBS}" @click="handleProyekDarurat(true)">
            <div v-if="isBS" class="ml-2 edit-icon-wrapper">
              <EditIcon color="#d9272d" />
            </div>
            <p class="color-red averta-bold is-underlined ml-1 label-sm-12 is-clickable" v-if="dataUrgentID.remainData > 0">{{dataUrgentID.remainData}} Lainnya</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { EditIcon } from '@/components/icons'

export default {
  name: 'SurveyProjectID',
  props: {
    dataHeaderProps: {
      type: Object,
      required: true
    }
  },
  components: {
    EditIcon
  },
  data () {
    return {
      dataHeader: null,
      dataUrgentID: {
        data: [],
        total: 0,
        remainData: 0
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    }),
    isBS () {
      if (this.user.roles[0].name === 'Account Manager' || this.user.roles[0].name === 'Business Support') {
        return true
      }
      return false
    }
  },
  methods: {
    handleProyekDarurat (value, modeEdit) {
      if (modeEdit && !this.isBS) return
      this.$emit('onModalProyekDarurat', { value, modeEdit })
    },
    checkWidthExceedsParent () {
      const containerProjectUrgent = document.getElementById('containerProjectID').offsetWidth
      const itemProjectUrgent = document.getElementsByClassName('item-project-urgent')
      // const titleIDPROYEK = document.getElementsByClassName('title-id-proyek-darurat').offsetWidth

      let totalWidth = 0
      let indexExceedingWidth = null
      itemProjectUrgent.forEach((item, index) => {
        totalWidth += item.offsetWidth
        if (totalWidth > (containerProjectUrgent - 110) && !indexExceedingWidth) {
          indexExceedingWidth = index
        }
      })

      const isMobile = window.innerWidth <= 768

      if (indexExceedingWidth !== null) {
        const limitMobile = indexExceedingWidth - 1
        const limitDekstop = indexExceedingWidth - 4
        const indexExceeding = isMobile ? limitMobile : limitDekstop

        this.dataUrgentID.data = this.dataHeader.project_urgent_id.slice(0, indexExceeding)
        this.dataUrgentID.remainData = this.dataUrgentID.total - this.dataUrgentID.data.length
      }
    },
    loadDataHeader (val, fromWatch) {
      if (val && Object.keys(val).length > 0) {
        this.dataHeader = val
        this.dataUrgentID.data = val.project_urgent_id
        this.dataUrgentID.total = val.project_urgent_id.length

        if (fromWatch) {
          setTimeout(() => {
            this.checkWidthExceedsParent()
          }, 300)
        }
      }
    }
  },
  mounted () {
    this.loadDataHeader(this.dataHeaderProps)
  },
  watch: {
    dataHeaderProps (val) {
      this.loadDataHeader(val, true)
    }
  }
}
</script>

<style scoped>
.container-project-id {
  border-radius: 12px;
  padding: 12px;
}

.border-right {
  border-right: 1px solid #C1C7D3;
}

.container-test {
  width: 5em;
}

.wrapper-test {
  width: 100%;
  display: flex;
}

.more-wrapper {
  margin-top: -6px;
}

.edit-icon-wrapper {
  padding-top: 5px;
  padding-bottom: 2px;
}

.jenis-proyek {
  background: #E1EEFF;
  height: max-content;
  padding: 2px 12px;
  border-radius: 100px;
  color: #3185FC;
}

</style>
