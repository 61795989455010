<template>
  <div>
    <div>
      <div class="is-flex is-justify-content-space-between mb-3 px-3">
        <p class="averta-bold label-14">Total</p>
        <div class="wrap-price is-flex is-align-items-center is-justify-content-space-between">
          <p class="averta-bold label-14">Rp</p>
          <p class="averta-bold label-14">
            {{ formatThousand(dataPreview.mark_up_price.subtotal) }}
          </p>
        </div>
      </div>
      <div class="is-flex is-justify-content-space-between mb-3 px-3">
        <p class="averta-bold label-14">PPN {{ dataPreview.mark_up_price.ppn_percent }}</p>
        <div class="wrap-price is-flex is-align-items-center is-justify-content-space-between">
          <p class="averta-bold label-14">Rp</p>
          <p class="averta-bold label-14">{{ formatThousand(dataPreview.mark_up_price.ppn) }}</p>
        </div>
      </div>
    </div>

    <div class="is-flex is-justify-content-space-between mb-3 is-size-5 wrapper-grand p-3">
      <p class="averta-black">Grand Total</p>
      <div
        class="text-rp-grand color-red averta-black is-flex is-align-items-center is-justify-content-space-between"
      >
        <p>Rp</p>
        <p class="averta-black">
          {{ formatThousand(dataPreview.mark_up_price.total) }}
        </p>
      </div>
    </div>
    <p class="has-text-right mt-2 label-13 pr-3">
      (<span class="averta-bold">Terbilang: </span> <span class="is-italic">{{spellRupiah(dataPreview.mark_up_price.total)}}</span>)
    </p>
  </div>
</template>

<script>
import { formatCurrency, Terbilang } from '@/helpers'

export default {
  name: 'TabActualPrice',
  props: {
    dataPreview: {
      type: Object
    }
  },
  methods: {
    formatThousand (value) {
      return formatCurrency(value, true)
    },
    spellRupiah (value) {
      return Terbilang(value)
    }
  },
  mounted () {
    console.log('asd ', this.dataPreview)
  }
}
</script>

<style scoped>
.divider {
  border-top: 2px dashed #e1e1e1;
  margin: 24px 0px;
}

.wrapper-grand {
  background: linear-gradient(90deg, #fffff7 0%, #fff5ea 100%);
  border-radius: 8px;
}

.wrap-price {
  width: 180px;
}

.text-rp-grand {
  width: 180px;
}
</style>
