<template>
  <div>
    <p class="averta-black label-20">Daftar Surat Izin Kerja (SIK)</p>
    <p class="mb-5">Dokumen ini digunakan untuk perizinan proses pengerjaan proyek</p>

    <table v-if="dataSIK.length > 0" class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
      <tr class="bg-dark-grey-5 has-text-white has-text-centered tr-wrapper">
        <td class="py-4">
          <p class="averta-bold">NO</p>
        </td>
        <td class="py-4">
          <p class="averta-bold">NAMA DOKUMEN</p>
        </td>
        <td class="py-4">
          <p class="averta-bold">DIUNGGAH TANGGAL</p>
        </td>
        <td class="py-4">
          <p class="averta-bold">TANGGAL TERBIT</p>
        </td>
        <td class="py-4">
          <p class="averta-bold">TANGGAL BERAKHIR</p>
        </td>
        <td class="py-4">
          <p class="averta-bold">STATUS</p>
        </td>
        <td class="py-4">
          <p class="averta-bold">CATATAN</p>
        </td>
        <td class="py-4"></td>
      </tr>
      <tr v-for="(item, index) in dataSIK" :key="index" class="vertical-middle tr-wrapper">
        <td class="py-3">
          <p class="averta-bold has-text-centered">{{ index+1 }}</p>
        </td>
        <td class="py-3">
          <p class="averta-bold">{{ item.name }}</p>
        </td>
        <td class="py-3">
          <div class="flex-center is-flex-direction-column">
            <p class="has-text-centered averta-bold">{{ formatDate(item.created_at) }}</p>
            <p class="has-text-centered">{{ item.created_by }}</p>
          </div>
        </td>
        <td class="py-3">
          <p class="averta-bold has-text-centered">{{ formatDate(item.start_date) }}</p>
        </td>
        <td class="py-3">
          <p class="averta-bold has-text-centered">{{ formatDate(item.end_date) }}</p>
        </td>
        <td class="py-3">
          <div class="is-flex is-justify-content-center is-align-items-center">
            <div
              :style="{
                backgroundColor: item.status === 'Berlaku' ? '#F1D900' : '#B40007',
                color: '#ffffff'
              }"
              class="averta-bold has-text-centered px-2 py-1 is-inline-block border-radius-100"
            >
              <p class="label-12">{{ item.status }}</p>
            </div>
          </div>
        </td>
        <td class="py-3">
          <p class="averta-bold">{{ item.note || '-' }}</p>
        </td>
        <td>
          <div class="flex-center">
            <div class="is-clickable mr-2 p-3" @click="openModalPreview(item.url)">
              <HamburgerDotIcon />
            </div>
          </div>
        </td>
      </tr>
    </table>

    <div v-else class="column c-w-100 has-text-centered">
      <ProyekEmptyIcon />
      <p class="averta-bold mt-2">
        Data Tidak Ditemukan
      </p>
    </div>

    <ModalPreviewImage
      :isActive="modalPreview"
      :urlOneImage="urlImagePreview"
      @closeModal="closeModalPreview"
    />

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { HamburgerDotIcon, TrashIcon, ProyekEmptyIcon } from '@/components/icons'
import { formatDateHelper, formatCurrency } from '@/helpers'
import ModalPreviewImage from '@/components/modal/ModalPreviewImage.vue'

export default {
  name: 'TableListSIK',
  props: {
    dataSIK: {
      type: Array
    }
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    TrashIcon,
    HamburgerDotIcon,
    ProyekEmptyIcon,
    ModalPreviewImage
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      getDetailClient: 'client/getDetailClient'
    })
  },
  data () {
    return {
      showModalHistory: false,
      modalPreview: false,
      urlImagePreview: ''
    }
  },
  methods: {
    formatDate (date) {
      return formatDateHelper({ date })
    },
    formatThousand (val) {
      return formatCurrency(val)
    },
    openModalPreview (url) {
      this.modalPreview = true
      this.urlImagePreview = url
    },
    closeModalPreview () {
      this.modalPreview = false
      this.urlImagePreview = ''
    }
  },
  mounted () {
  }
}
</script>

<style scoped>
table {
  overflow: auto;
  width: 100%;
  table-layout: fixed;
}
td:first-child, th:first-child {
  left: 0;
  width: 5%;
  min-width: 5%;
  max-width: 5%;
}
td:nth-child(2), th:nth-child(2),
td:nth-child(3), th:nth-child(3),
td:nth-child(4), th:nth-child(4),
td:nth-child(5), th:nth-child(5),
td:nth-child(6), th:nth-child(6),
td:nth-child(7), th:nth-child(7) {
  width: 15%;
  overflow: hidden;
}
td:nth-child(2), th:nth-child(2){
  width: 15%;
  min-width: 15%;
  max-width: 15%;
}
td:nth-child(3), th:nth-child(3){
  width: 15%;
  min-width: 15%;
  max-width: 15%;
}
td:nth-child(4), th:nth-child(4){
  width: 15%;
  min-width: 15%;
  max-width: 15%;
}
td:nth-child(5), th:nth-child(5){
  width: 15%;
  min-width: 15%;
  max-width: 15%;
}
td:nth-child(6), th:nth-child(6){
  width: 10%;
  min-width: 10%;
  max-width: 10%;
}
td:nth-child(7), th:nth-child(7){
  width: 20%;
  min-width: 20%;
  max-width: 20%;
}
td:last-child, th:last-child {
  right: 0;
  width: 5%;
  min-width: 5%;
  max-width: 5%;
}
</style>
