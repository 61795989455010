<template>
  <div v-if="dataSurvey" class="card-deskripsi-proyek p-4 mb-4">
    <p class="averta-bold label-20 mb-4">{{title}}</p>
    <div class="steps">
      <ul>
        <li v-for="(item, index) in isConfirmOffering ? dataSurvey.offering_history : dataSurvey.status_history" :key="index" class="li-wrapper" :class="{'completed': item.checked === true, 'confirm-offering': isConfirmOffering}">
          <div v-if="item.checked === true" class="step-marker">
            <StatusProyek :color="isConfirmOffering ? '#3185FC' : '#10BD41' " />
          </div>
          <div v-else-if="!item.checked && item.total_late" class="step-marker">
            <InfoIcon width="16" height="16" />
          </div>
          <div v-else-if="item.checked === 'reject'" class="step-marker">
            <RejectStatus />
          </div>
          <div v-else class="step-marker">
            <CircleEmpty />
          </div>
          <div class="step-details level is-flex is-justify-content-space-between is-align-items-flex-start">
            <div class="left-side">
              <p class="step-title averta-bold" :class="{'color-red': !item.checked && item.total_late}">
                {{ item.status }}
              </p>
              <p v-if="isConfirmOffering" class="color-gray label-12">Oleh : {{item.by}} </p>
            </div>
            <div class="level-right right-side">
              <div v-if="!item.checked && item.total_late">
                <div class="flex-center-vertical is-justify-content-flex-end">
                  <InfoIcon width="12" height="12" />
                  <p class="averta-bold ml-1 label-12 has-text-right white-space-nowrap">Telat {{item.total_late}} Hari </p>
                </div>
                <p class="averta-reguler color-grey is-italic label-12 has-text-right white-space-nowrap">Tgl Awal: {{formatDateStatus(item.actual_date, 'DD/MM/YY')}}</p>
              </div>
              <div v-else>
                <p class="averta-reguler label-12 color-grey has-text-right">
                  {{ item.created_at ? formatDateStatus(item.created_at) : item.date ? formatDateStatus(item.date) : '' }}
                </p>
                <div v-if="item.total_late" class="flex-center-vertical is-justify-content-flex-end">
                  <InfoIcon width="12" height="12" />
                  <p class="averta-bold ml-1 label-12 has-text-right white-space-nowrap">Telat {{item.total_late}} Hari </p>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { StatusProyek, CircleEmpty, RejectStatus, InfoIcon } from '@/components/icons'
export default {
  name: 'StatusProyekComponent',
  props: {
    dataSurvey: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    isConfirmOffering: {
      type: Boolean,
      default: false
    }
  },
  components: {
    StatusProyek,
    CircleEmpty,
    RejectStatus,
    InfoIcon
  },
  methods: {
    formatDateStatus (date, format) {
      let defaultFormat = 'DD MMM YYYY'

      if (format) {
        defaultFormat = format
      }

      if (!date) {
        return ''
      }
      return moment(date).format(defaultFormat)
    }
  }
}
</script>

<style scoped>
.card-deskripsi-proyek {
  border: 0.7px solid #d8d8d8;
  border-radius: 12px;
  font-size: 14px;
}

.steps {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.steps ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.steps li {
  display: flex;
  position: relative;
}

.li-wrapper:not(:last-child) {
  padding-bottom: 1.7em;
}

.li-wrapper:not(:last-child):before {
  content: '';
  position: absolute;
  height: 100%;
  left: 7px;
  bottom: 0;
  border-left: 2px dashed #ccc;
}

.step-marker {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  position: relative;
}

.li-wrapper.completed:not(:last-child):before {
  border-left: 2px dashed #10bd41;
}

.li-wrapper.completed.confirm-offering:not(:last-child):before {
  border-left: 2px dashed #3185FC;
}

.step-details {
  flex: 1;
  margin-top: -3px;
}

.step-title {
  font-size: 0.875rem;
}

.step-description {
  color: #868686;
  margin-left: 0.5rem;
}

.left-side {
  width: 63%;
}

.right-side {
  flex: 1;
}
</style>
