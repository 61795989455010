<template>
  <div v-if="!isLoading">
    <StatusMaterial :dataProps="detailOffering && detailOffering.dataStatusMaterial" />
    <NewDetailPenawaran :isCalledButtonArea="false" :offeringId="offeringId" :isBs="true" :isProject="true" :purchasingModal="false" :hideInformasiPenawaran="true" :hideTimeline="true" :showButtonAnimation="showButtonAnimation" class="px-5 wrap-detail-penawaran" />
  </div>
  <div v-else class="column has-text-centered">
    <ProyekEmptyIcon />
    <p class="averta-bold mt-2">
      Material & Tukang Tidak Ditemukan
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

// Assets
import { ProyekEmptyIcon } from '@/components/icons'

// Components
import NewDetailPenawaran from '@/components/project/NewDetailPenawaran'
import StatusMaterial from './StatusMaterial.vue'

export default {
  name: 'MaterialAndWorker',
  props: ['isLoading', 'offeringId', 'showButtonAnimation'],
  components: {
    // Icon
    ProyekEmptyIcon,

    // Components
    NewDetailPenawaran,
    StatusMaterial
  },
  computed: {
    ...mapGetters({
      detailOffering: 'project/getDataDetailPenawaran'
    })
  },
  data () {
    return {
      modalPenawawaranClient: false
    }
  },
  methods: {
    previewPenawaranClient () {
      this.modalPenawawaranClient = !this.modalPenawawaranClient
    }
  }
}

</script>
