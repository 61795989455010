<template>
  <div class="container-wrapper min-h-80vh">
    <div v-if="isLoading" class="loading-wrapper">
      <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false">
        <SpinnerIcon />
      </b-loading>
    </div>
    <div v-else>
      <div class="indicator-wrapper" :style="{ top: navbarPosition }">
        <CardPriceTypeOffering
          :isPriceActual="false"
          styleProps="position: relative; padding: 15px 30px !important; margin: 0 !important;"
        />
      </div>
      <div class="button-wrapper">
        <ButtonApp
          width="195px"
          height="46px"
          :is-bold="true"
          :hover-enabled="false"
          @click="downloadPDF"
        >
          <b-icon icon="download" size="is-small" />
          {{ isLoadingSpinner ? 'Mengunduh...' : 'Unduh Penawaran' }}
        </ButtonApp>
      </div>
      <div
        class="invoice border-1 my-5"
        ref="document"
        v-for="(item, index) in paginatedList"
        :key="`item-${index}`"
      >
        <div :class="`padding-page-pdf invoice-${index + 1}`">
          <div class="height-body-pdf">
            <div v-if="index === 0">
              <!-- Header / Cop Letter -->
              <div class="header-cop-letter">
                <div class="logo-image-cop-letter">
                  <div class="logo-branch">
                    <img :src="dataOffering.branch_logo" alt="SMRCN"/>
                  </div>
                </div>

                <div class="Heading-cop-letter">
                  <h3>FORMULIR</h3>
                  <h3>ESTIMASI BIAYA</h3>
                </div>
              </div>
              <!-- Header / Cop Letter -->

              <!-- Sub Heading -->
              <div class="is-flex is-flex-direction-column is-justify-content-flex-start averta-bold mt-2 label-14">
                <h3>Sehubungan dengan adanya permintaan perbaikan {{dataOffering.description || ''}} berdasarkan permintaan dari tenant</h3>
              </div>
              <!-- Sub Heading -->

              <div class="py-5">
                <!-- Information Letter -->
                <div class="is-flex averta-bold label-14 mb-2">
                  <h3 style="width: 100px">Nama</h3>
                  <h3>:</h3>
                  <div class="ml-3 dashed"></div>
                </div>
                <div class="is-flex averta-bold label-14">
                  <h3 style="width: 100px">Unit</h3>
                  <h3>:</h3>
                  <div class="ml-3 dashed">{{dataOffering.unit_name}}</div>
                </div>
                <!-- Information Letter -->

                <!-- Sub Heading -->
                <div class="is-flex is-flex-direction-column is-justify-content-flex-start mt-5">
                  <h3 class="averta-bold label-14">Dengan ini terlampir rincian sebagai berikut :</h3>
                </div>
                <!-- Sub Heading -->
              </div>
            </div>

            <!-- Body Letter -->
            <div class="body-letter">
              <div v-if="item.list">
                  <div class="invoice-table border-head-table has-text-centered">
                    <!-- Header Row -->
                    <div class="averta-bold">No</div>
                    <div class="averta-bold">Jenis Pekerjaan</div>
                    <div class="averta-bold">Jumlah</div>
                    <div class="averta-bold">Harga Satuan (Rp)</div>
                    <div class="averta-bold">Total Harga (Rp)</div>
                  </div>

                  <div class="invoice-table" v-for="(itemList, indexList) in item.list" :key="`itemList-${indexList}`">
                    <!-- Data Row -->
                    <div class="averta-bold has-text-centered">{{itemList.no}}</div>
                    <div class="has-text-left averta-bold">{{itemList.title_type_working}}</div>
                    <div class="averta-bold has-text-centered">{{itemList.volume}} {{itemList.unit_name}}</div>
                    <div class="has-text-right averta-bold">{{formatThousand(itemList.unit_price)}}</div>
                    <div class="has-text-right averta-bold">{{formatThousand(itemList.total_price)}}</div>
                  </div>
              </div>

              <div v-if="item.section.totalSummary" class="invoice-table-footer">
                <!-- Total Data Row -->
                <div class="total-row label-14 averta-bold has-text-right">Total</div>
                <div class="total-row label-14 averta-bold has-text-right">{{formatThousand(dataOffering.prices.subtotal)}}</div>

                <!-- Total Data Row -->
                <div class="total-row label-14 averta-bold has-text-right">PPN {{dataOffering.prices.ppn_percent}}%</div>
                <div class="total-row label-14 averta-bold has-text-right">{{formatThousand(dataOffering.prices.ppn)}}</div>

                <!-- Total Data Row -->
                <div class="total-row label-14 averta-bold has-text-right">GRAND TOTAL</div>
                <div class="total-row label-14 averta-bold has-text-right">{{formatThousand(dataOffering.prices.total)}}</div>
              </div>
            </div>
            <!-- Body Letter -->

            <div v-if="item.section.noteTerms">
              <!-- Note Letter -->
              <div class="is-flex is-align-items-flex-start pt-5">
                <div class="is-flex is-align-items-flex-start label-14 averta-bold">Note :</div>
                <div class="is-flex is-flex-direction-column is-justify-content-flex-start is-align-items-flex-start ml-5 label-14 averta-bold c-w-85">
                  <p
                    v-for="(term, indexTerm) in dataOffering.tenant_terms.terms"
                    :key="`terms-tenant-${indexTerm}`"
                    :class="{'mb-2': indexTerm === 0}"
                  >
                    - {{ term.value }}
                  </p>
                </div>
              </div>
              <!-- Note Letter -->

              <!-- Date Letter -->
              <p class="is-flex is-align-items-center mt-5 label-14 averta-bold">{{dataOffering.area_name}}, {{dataOffering.current_date_time}}</p>
              <!-- Date Letter -->
            </div>

            <!-- Signature Letter -->
            <div
              v-if="item.section.signature"
              style="gap: 2.5rem"
              class="is-flex is-align-items-center pt-5"
            >
              <div
                v-for="(signature, indexSignature) in dataOffering.tenant_terms.signature"
                :key="`signature-tenant-${indexSignature}`"
                style="min-height: 150px;"
                class="is-flex is-flex-direction-column is-justify-content-space-between is-align-items-flex-start"
              >
                <h5 class="label-14 averta-bold">{{signature.preword}}</h5>
                <h5 style="border-top: 1px dashed black;" class="label-14 averta-bold">{{signature.division}}</h5>
              </div>
            </div>
            <!-- Signature Letter -->

            <div v-if="item.section.codeFormat">
              <!-- Code Format Letter -->
              <div style="gap: 2rem;" class="is-flex is-align-items-center pt-6">
                <h5
                  v-for="(note, indexNote) in dataOffering.tenant_terms.notes"
                  :key="`note-term-tenant-${indexNote}`"
                  class="label-14 averta-bold"
                >
                  {{note}}
                </h5>
              </div>
              <!-- Code Format Letter -->

              <!-- Code Letter -->
              <!-- <div style="gap: 2rem;" class="is-flex is-align-items-center pt-2">
                <h5 class="label-14 averta-bold">{{dataOffering.tenant_terms.letter_number}}</h5>
              </div> -->
              <!-- Code Letter -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import moment from 'moment'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { mapGetters } from 'vuex'
import { SpinnerIcon } from '@/components/icons'
import { alertErrorMessage, formatCurrency, Terbilang } from '@/helpers'
import ButtonApp from '@/components/button/ButtonApp'
import CardPriceTypeOffering from '@/components/card/CardPriceTypeOffering.vue'

export default {
  name: 'OfferingTenantPDF',
  components: {
    SpinnerIcon,
    ButtonApp,
    CardPriceTypeOffering
  },
  data () {
    return {
      isLoading: true,
      isLoadingSpinner: false,
      dataOffering: null,
      dataList: null,
      url: process.env.VUE_APP_SHELL_URL_API,
      navbarPosition: null
    }
  },
  computed: {
    ...mapGetters({
      heightNavbar: 'dashboard/getNavbarHeight'
    }),
    paginatedList () {
      const INITIAL_PAGE = 970
      const MIDLE_PAGE = 1050
      const WIDTH_TYPE_WORKING = 333
      const HEIGHT_RECIPIENT = 145
      const HEIGHT_HEADER_TABLE = 25
      const HEIGHT_FOOTER_TABLE = 76
      const HEIGHT_NOTE_TERMS = 161
      const HEIGHT_SIGNATURE = 174
      const HEIGHT_CODE_FORMAT = 98

      const pages = []
      let currentPage = []
      let currentHeight = 0

      // ================================ HEIGHT_RECIPIENT
      currentHeight += HEIGHT_RECIPIENT

      // NOTE DESCRIPTION
      const pDesc = $('<p>').text('lorem')
      $('body').append(pDesc)
      const heightDesc = pDesc.height()
      pDesc.remove()

      // ================================ heightDesc
      currentHeight += heightDesc

      // ================================ HEIGHT_HEADER_TABLE
      currentHeight += HEIGHT_HEADER_TABLE

      let countNumber = 0
      if (this.dataList.length > 0) {
        this.dataList.forEach(itemArea => {
          itemArea.components.forEach(itemComp => {
            itemComp.item_components.forEach(itemList => {
              countNumber++
              itemList.no = countNumber

              const pTemp = `${itemArea.area_name} - ${itemComp.component_name} - ${itemList.note_item_working}`
              const divTypeWorking = $('<div>').text(pTemp)
              divTypeWorking.width(`${WIDTH_TYPE_WORKING}px`).addClass('has-text-left averta-bold').css('font-size', '12px')

              itemList.title_type_working = pTemp
              $('body').append(divTypeWorking)

              // 2px = padding top and bottom, 1px = border div, 1px border div
              const countHeight = divTypeWorking.height() + 2 + 1 + 1
              const heightTypeWorking = countHeight < 25 ? 25 : countHeight
              divTypeWorking.remove()

              if (currentHeight + heightTypeWorking > INITIAL_PAGE) {
                pages.push({
                  page: pages.length + 1,
                  list: currentPage,
                  section: {
                    totalSummary: false,
                    noteTerms: false,
                    signature: false,
                    codeFormat: false
                  }
                })
                currentPage = []
                currentHeight = 0
              }

              currentPage.push(itemList)
              currentHeight += heightTypeWorking
            })
          })
        })

        if (currentPage.length) {
          pages.push({
            page: pages.length + 1,
            list: currentPage,
            section: {
              totalSummary: false,
              noteTerms: false,
              signature: false,
              codeFormat: false
            }
          })
        }

        let totalHeight = 0

        // IS EXCEED TOTAL PRICE SUMMARY
        const countTotalSummary = currentHeight + HEIGHT_FOOTER_TABLE
        if (countTotalSummary < (pages.length > 1 ? MIDLE_PAGE : INITIAL_PAGE)) {
          totalHeight = countTotalSummary
          pages[pages.length - 1].section.totalSummary = true
        } else {
          pages.push({
            section: {
              totalSummary: true,
              noteTerms: false,
              signature: false,
              codeFormat: false
            }
          })
          totalHeight = 0
        }

        // IS EXCEED NOTE TERMS
        const countNoteTerms = totalHeight + HEIGHT_NOTE_TERMS
        if (countNoteTerms < (pages.length > 1 ? MIDLE_PAGE : INITIAL_PAGE)) {
          totalHeight = countNoteTerms
          pages[pages.length - 1].section.noteTerms = true
        } else {
          pages.push({
            section: {
              totalSummary: false,
              noteTerms: true,
              signature: false,
              codeFormat: false
            }
          })
          totalHeight = 0
        }

        // IS EXCEED SIGNATURE
        const countSignature = totalHeight + HEIGHT_SIGNATURE
        if (countSignature < (pages.length > 1 ? MIDLE_PAGE : INITIAL_PAGE)) {
          totalHeight = countSignature
          pages[pages.length - 1].section.signature = true
        } else {
          pages.push({
            section: {
              totalSummary: false,
              noteTerms: false,
              signature: true,
              codeFormat: false
            }
          })
          totalHeight = 0
        }

        // IS EXCEED CODE FORMAT
        const countCodeFormat = totalHeight + HEIGHT_CODE_FORMAT
        if (countCodeFormat < (pages.length > 1 ? MIDLE_PAGE : INITIAL_PAGE)) {
          totalHeight = countCodeFormat
          pages[pages.length - 1].section.codeFormat = true
        } else {
          pages.push({
            section: {
              totalSummary: false,
              noteTerms: false,
              signature: false,
              codeFormat: true
            }
          })
          totalHeight = 0
        }

        return pages
      }
      return []
    }
  },
  methods: {
    getDataDetailClient () {
      this.isLoading = true
      const payload = {
        url: this.url,
        tenant: true,
        offering_id: this.$route.params.offeringId
      }

      if (this.$route.query.preview === 'true') {
        payload.preview = true
      }

      this.$store
        .dispatch('project/getDataDetailClient', payload)
        .then(response => {
          const res = response.data.data

          this.dataList = res.item_components
          this.dataOffering = res
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    },
    async downloadPDF () {
      if (this.isLoadingSpinner) return
      this.isLoadingSpinner = true
      // eslint-disable-next-line new-cap
      const pdf = new jsPDF('p', 'mm', 'a4') // Create A4-sized PDF

      for (let index = 0; index < this.paginatedList.length; index++) {
        if (index > 0) {
          pdf.addPage() // Add a new page for each item after the first one
        }
        const selector = `.invoice-${index + 1}`

        // Capture content and add it to the PDF
        await this.captureContent(selector, pdf)
      }

      // Save the PDF
      const filename = `${this.dataOffering.offering_number || 'penawaran_' + moment().format('DD-MM-YYYY')}.pdf`
      pdf.save(filename)
      this.isLoadingSpinner = false
    },
    async captureContent (selector, pdf) {
      const element = document.querySelector(selector)
      return html2canvas(element, { scale: 2, allowTaint: true, useCORS: true })
        .then(canvas => {
          const imgWidth = 208
          const imgHeight = (canvas.height * imgWidth) / canvas.width

          const imageData = canvas.toDataURL('image/jpeg')
          pdf.addImage(
            imageData,
            'JPEG',
            1,
            0,
            imgWidth,
            imgHeight,
            '',
            'FAST'
          )
        })
        .catch(error => console.error('Error capturing content:', error))
    },
    adjustStickyPosition () {
      setTimeout(() => {
        if (this.heightNavbar) {
          this.navbarPosition = this.heightNavbar + 'px'
        }
      }, 600)
    },
    numberList (value) {
      return formatCurrency(value, true) + '.'
    },
    formatThousand (value) {
      return formatCurrency(value, true)
    },
    formatSpellOut (value) {
      return Terbilang(value)
    }
  },
  mounted () {
    this.adjustStickyPosition()
  },
  created () {
    this.getDataDetailClient()
  },
  watch: {
    heightNavbar (val) {
      if (val) {
        this.adjustStickyPosition()
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.container-wrapper {
  margin-top: 5vh;
  width: 100%;
}

.button-wrapper {
  width: 210mm;
  display: flex;
  justify-content: flex-end;
  margin: auto;
  margin-top: 4em;
}

.invoice {
  position: relative;
  width: 210mm;
  height: 297mm;
  padding: 0;
  margin: 0 auto;
  display: block;
}

.indicator-wrapper {
  background: #f4f9ff;
  width: 100%;
  position: fixed;
  top: 12vh;
  z-index: 2;
  display: flex;
  align-items: center;
}

.header-cop-letter{
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  border: 1px solid black;
}

.logo-image-cop-letter{
  display: flex;
  flex-direction: row;
  width: 70mm;
  height: 100px;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  border-right: 1px solid black;
}

.logo-branch {
  height: 85px;
  width: 190px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-image-cop-letter img{
  object-fit: contain;
  object-position: center;
  width: 100%;
  height: 100%;
}

.Heading-cop-letter{
  display: flex;
  flex-direction: column;
  width: 140mm;
  height: 100px;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}

.Heading-cop-letter > h3{
  font-size: 20px;
  font-weight: 800;
  display: flex;
  flex-direction: column;
  width: 140mm;
  height: 50px;
  max-height: 50px;
  justify-content: center;
  align-items: center;
  color: black;
  border-bottom:1px solid black;
}

.sub-header-cop-letter{
  width: 210mm;
  font-size: 14px;
}

.dashed {
  width: 300px;
  border-bottom: 1px dashed black;
}

.body-letter{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.invoice-table {
  display: grid;
  grid-template-columns: 0.7fr 5fr 1fr 2fr 2fr;
  border-left: 0.5px solid black;
  border-right: 0.5px solid black;
  border-collapse: collapse;
  width: 100%;
  margin: auto;
}

.invoice-table.border-head-table {
  border-top: 0.5px solid black;
}

.invoice-table-footer {
  display: grid;
  grid-template-columns: 8.7fr 2fr;
  border: 0.5px solid black;
  border-top: none !important;
  border-collapse: collapse;
  width: 100%;
  margin: auto;
}

.invoice-table div, .invoice-table-footer div {
  border: 0.5px solid black;
  padding: 1px 5px;
  min-height: 25px;
  font-size: 12px;
}

.invoice-table .total-row, .invoice-footer .total-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  border: 0.5px solid black;
  min-height: 25px;
}

</style>
