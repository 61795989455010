<template>
  <div>
    <div class="is-flex is-justify-content-space-between mb-3">
      <div class="wrapper-alamat has-text-right">
        <img src="@/assets/img/logo-kanggo-2.png" alt="logo-kanggo" class="img-kanggo" />
      </div>
      <div v-if="type === 'invoice'">
        <p class="color-blue-1 averta-black is-size-4">INVOICE</p>
        <div class="flex-center-vertical">
          <p class="has-text-grey">
            No. Invoice:
            {{ removePrefix(invoice) || '-' }}
          </p>
          <div class="is-clickable hide-while-download ml-1" @click="handleEditInvoice"><EditIcon size="14" /></div>
        </div>
        <p class="has-text-grey">
          Tanggal Terbit:
          {{ convertDate(createdDate) }}
        </p>
        <p class="has-text-grey">
          Jatuh Tempo:
          <span class="averta-bold has-text-dark">{{convertDate(dueDate)}}</span>
        </p>
      </div>
    </div>

    <div v-if="index === 0">
      <div
        class="bg-light-blue-3 notification is-info is-light p-4 has-text-grey is-flex is-justify-content-space-between mb-4 border-radius-20"
      >
        <div class="wrapper-store pr-3">
          <p class="mb-1">Kepada Yth:</p>
          <div class="is-flex">
            <div class="c-w-100">
              <p class="averta-bold has-text-dark mr-2 mt-1 white-space-pre-line word-wrap-break">
                {{ recipient || '-' }}
              </p>
            </div>
            <div v-if="type === 'invoice'" class="is-flex hide-while-download">
              <ModalEditRecipient :refreshData="refreshData" :recipient="recipient" />
              <ModalHistoryEdit v-if="recipient" />
            </div>
          </div>
        </div>
        <div class="wrapper-address">
          <p class="mb-1">Alamat</p>
          <div class="is-flex is-justify-content-space-between">
            <p class="has-text-dark">
              {{ address }}
            </p>
            <ModalEditAddress v-if="type === 'invoice'" :refreshData="refreshData" :address="address" />
          </div>
        </div>
      </div>

      <div class="is-flex is-flex-direction-row is-justify-content-space-between card-duration-project mb-4">
        <p class="averta-bold">{{storeName}} | ID{{surveyId}}</p>
        <p v-if="duration">Durasi Kerja: <span class="averta-black color-blue-1 label-22">{{duration}}</span> Hari</p>
      </div>
    </div>

    <ModalInvoiceTagihan
      width="598px"
      :data="{noInvoice: invoice, projectId}"
      :showModal="showModalInvoice"
      @closeModal="handleEditInvoice"
      @reloadList="handleConfirmEditInvoice"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { EditIcon } from '@/components/icons'
import ModalHistoryEdit from './ModalHistoryEdit.vue'
import ModalEditRecipient from './ModalEditRecipient.vue'
import ModalEditAddress from './ModalEditAddress.vue'
import ModalInvoiceTagihan from '@/components/modal/ModalInvoiceTagihan.vue'

export default {
  name: 'HeaderSection',
  components: {
    EditIcon,
    ModalHistoryEdit,
    ModalEditRecipient,
    ModalEditAddress,
    ModalInvoiceTagihan
  },
  props: {
    type: {
      type: String // invoice | offering
    },
    index: {
      type: [String, Number]
    },
    recipient: {
      type: String
    },
    address: {
      type: String
    },
    storeName: {
      type: String
    },
    surveyId: {
      type: [String, Number]
    },
    projectId: {
      type: Number
    },
    invoice: {
      type: String
    },
    // FOR INVOICE
    createdDate: {
      type: String
    },
    dueDate: {
      type: String
    },
    duration: {
      type: Number
    },
    refreshData: {
      type: Function
    }
  },
  data () {
    return {
      showModalInvoice: false
    }
  },
  methods: {
    convertDate (date) {
      if (!date) return '-'
      return moment(date).format('DD MMMM YYYY')
    },
    removePrefix (value) {
      if (!value) {
        return '-'
      } else if (value && value.startsWith('#INV')) {
        return value.slice(4)
      }
      return value
    },
    handleEditInvoice () {
      this.showModalInvoice = !this.showModalInvoice
    },
    handleConfirmEditInvoice () {
      this.showModalInvoice = false
      this.refreshData({ hideLoading: true })
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';
.wrapper-store {
  width: 50%;
  font-size: 14px;
}

.wrapper-address {
  width: 50%;
  font-size: 14px;
}

.wrapper-alamat {
  width: 300px;
  display: flex;
  align-items: center;
}

.img-kanggo {
  width: 130px;
}

.card-duration-project {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 20px;
  width: 100%;
  height: auto;
  background: rgba(49, 133, 252, 0.1);
  border: 2px solid #3185fc;
  border-radius: 12px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

</style>
