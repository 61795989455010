<template>
  <div class="container-tab-wrapper">
    <div v-if="isLoading" style="height: 15em;">
      <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false">
        <SpinnerIcon />
      </b-loading>
    </div>
    <div v-else-if="Object.keys(this.data).length === 0" class="columns is-centered">
      <div class="column has-text-centered">
        <ProyekEmptyIcon />
        <p class="averta-bold mt-2">
          Detail Survey Proyek Kosong
        </p>
      </div>
    </div>
    <div v-else class="columns is-variable is-2">
      <div class="column is-8">
        <div class="is-flex flex-sm-column">
          <div class="card card-wrapper-costum mr-4 mr-sm-0 mb-sm-2 mb-0 flex-1 p-4">
            <p class="text-title averta-bold">Deskripsi Proyek</p>
            <div class="mb-3">
              <p class="label-14 color-gray">Pesanan</p>
              <p class="averta-bold">{{project.type}}</p>
            </div>
            <div class="mb-3">
              <p class="label-14 color-gray">Pesanan Dibuat</p>
              <p class="averta-bold">{{project.client_name}}</p>
            </div>
            <div class="mb-3">
              <p class="label-14 color-gray">Deskripsi Proyek</p>
              <p class="averta-bold">{{project.description || '-'}}</p>
            </div>
          </div>
          <div class="card card-wrapper-costum flex-1 p-4">
            <p class="text-title averta-bold">Informasi Client</p>
            <div class="mb-3">
              <p class="label-14 color-gray">Nama PIC</p>
              <p class="averta-bold">{{client.pic_name}}</p>
            </div>
            <div class="mb-3">
              <p class="label-14 color-gray">Telp PIC</p>
              <p class="averta-bold">{{client.pic_phone}}</p>
            </div>
            <div class="mb-3">
              <p class="label-14 color-gray">Alamat Pengerjaan</p>
              <p class="averta-bold color-red">{{client.address}}</p>
            </div>
          </div>
        </div>
        <div class="card card-wrapper-costum p-4 mb-3 mt-4">
          <p class="averta-bold is-size-5 mb-4">Detail Proyek</p>
          <div class="wrapper-tanggal-survey is-flex is-justify-content-space-between mb-3">
            <div class="is-flex is-align-items-center flex-sm-column align-items-sm-start flex-sm-row">
              <CalendarIcon class="mr-3" />
              <div class="is-flex is-flex-direction-column">
                <p class="color-blue" style="font-size: 12px;">Tanggal Survey</p>
                <p class="averta-bold">{{ formatDate(detail.date) }}</p>
              </div>
            </div>
            <div class="wrapper-skill">
              <div class="is-flex is-align-items-center averta-bold is-justify-content-end justify-content-sm-start">
                <img
                  v-if="detail.skill_image"
                  :src="detail.skill_image"
                  alt="skill_image"
                  class="skill-image mr-3"
                />
                <p>{{ detail.skill_name ? detail.skill_name : '-' }}</p>
              </div>
            </div>
          </div>
          <div class="wrapper-tukang">
            <div class="is-flex is-justify-content-space-between is-align-items-center flex-sm-column align-items-sm-start">
              <div class="is-flex is-align-items-center">
                <div class="mr-3 photo-wrapper">
                  <img
                    v-if="detail.worker_image"
                    :src="detail.worker_image"
                    alt="worker_image"
                  />
                </div>
                <div>
                  <p class="averta-bold">{{ detail.worker_name }}</p>
                  <p>{{ detail.worker_id ? '# ID-' + detail.worker_id : '-'}}</p>
                </div>
              </div>
              <div class="is-flex wrapper-absen">
                <p>Absen Masuk : <span style="padding-right: 2px" :class="{'averta-bold': isValidDate(detail.attendance_start_date), 'color-red is-italic': !isValidDate(detail.attendance_start_date)}">{{ absen(detail.attendance_start_date)}}</span></p>
                <p class="mx-3 is-hidden-mobile">|</p>
                <p>Absen Keluar : <span style="padding-right: 2px" :class="{'averta-bold': isValidDate(detail.attendance_end_date), 'color-red is-italic': !isValidDate(detail.attendance_end_date)}">{{ detail.attendance_end_date ? absen(detail.attendance_end_date) : 'Belum Absen' }}</span></p>
              </div>
            </div>
            <div v-if="detail.note" class="mb-3 note-konsultan">
              <p class="averta-bold">Catatan dari konsultan</p>
              <p class="white-space-preline">{{ detail.note ? detail.note : 'Belum ada catatan' }}</p>
            </div>
          </div>

          <p class="averta-bold mb-3 mt-4">Foto Survey</p>
          <p v-if="!detail.images" class="is-italic has-text-grey">Empty</p>

          <div v-if="detail.images.length > 0" class="wrapper-file-photo is-flex is-align-items-center">
            <div
              class="wrapper-file flex-shrink-0 word-wrap-break"
              v-for="dataImage, indexImage in detail.images"
              :key="dataImage.id"
            >
              <img class="image-survey mb-2 is-clickable" :src="dataImage.filename" :alt="dataImage.name" @click="handlePreviewImage(detail.images, 'photoSurvey', indexImage)">
              <b-tooltip :position="indexImage > 4 ? indexImage + 1 >= detail.images.length - 3 ? 'is-left' : 'is-right' : 'is-right'" type="is-dark" :triggers="['hover']" :auto-close="true">
                <template v-slot:content>
                  <div style="max-width: 300px;" class="word-wrap-break">
                    <p class="white-space-normal">{{ extractFileName(dataImage.name) }}</p>
                  </div>
                </template>
                <p class="averta-bold color-blue-1 image-name white-space-normal">{{ extractFileName(dataImage.name) }}</p>
              </b-tooltip>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-4">
        <!-- Status Proyek -->
        <StatusProyekComponent :dataSurvey="data" title="Status Proyek" />

        <!-- Penawaran Dalam Proses Internal -->
        <StatusProyekComponent v-if="data && data.offering_history" :dataSurvey="data" title="Penawaran Dalam Proses Internal" :isConfirmOffering="true" />

        <!-- <div v-if="role === 'am' && status === 'Survey Selesai'" class="card card-wrapper-costum p-4">
          <p class="averta-black is-size-5 mb-4">Total Harga</p>
          <div class="is-flex is-align-items-center averta-bold">
            <img src='source_img' alt="skill_image" class="skill-image mr-3">
            <p>Konsultan</p>
          </div>
          <div class="averta-bold mt-3 is-flex is-align-items-center is-justify-content-space-between">
            <p>1 x Rp {{formatThousand(0)}}</p>
            <p class="address">GRATIS</p>
          </div>
          <div class="total-wrapper averta-bold">
            <p>Total : </p>
            <p class="color-blue ml-2">GRATIS</p>
          </div>
        </div> -->
      </div>

      <ModalPreviewImage :indexImg="indexImg" :isActive="previewImage" :isOneImage="true" :dataImage="imagePreview" @closeModal="closePreviewImage()" />

    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { CalendarIcon, ProyekEmptyIcon, SpinnerIcon } from '@/components/icons'
import { mapGetters } from 'vuex'
import StatusProyekComponent from '@/components/project/detail/StatusProyekComponent'
import ModalPreviewImage from '@/components/modal/ModalPreviewImage'

export default {
  name: 'TabHistorySurvey',
  props: ['data', 'role', 'status', 'surveyId'],
  components: {
    CalendarIcon,
    ProyekEmptyIcon,
    SpinnerIcon,
    StatusProyekComponent,
    ModalPreviewImage
  },
  data () {
    return {
      isLoading: true,
      client: null,
      detail: null,
      project: null,
      status_history: null,
      idSurvey: null,

      previewImage: false,
      imagePreview: null,
      indexImg: 0
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  methods: {
    formatDate (date) {
      if (!date) {
        return '-'
      }

      return moment(date).format('DD MMMM YYYY')
    },
    formatThousand (x) {
      if (!x && x !== 0) {
        return '-'
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    formatBytes (bytes, decimals = 2) {
      if (!+bytes) return '0 Bytes'

      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

      const i = Math.floor(Math.log(bytes) / Math.log(k))

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    },
    absen (date) {
      const varDate = moment(date)
      if (varDate.isValid() && date) {
        return moment.utc(date).format('HH:mm')
      }
      return 'Belum Absen'
    },
    isValidDate (date) {
      if (moment(date).isValid() && date) {
        return true
      } return false
    },
    handlePreviewImage (data, type, index) {
      console.log(data)
      this.indexImg = index
      if (type === 'photoSurvey') {
        this.imagePreview = data.map((item) => {
          return item.filename
        })
      } else {
        if (data && data !== '-') {
          this.imagePreview = data
        }
      }

      this.previewImage = true
    },
    closePreviewImage () {
      this.imagePreview = null
      this.previewImage = false
    },
    extractFileName (fileName) {
      if (!fileName) {
        return '-'
      }

      const parts = fileName.split('.')

      const name = parts[0]

      const lastIndex = parts.length - 1
      const format = parts[lastIndex]

      return `${name}.${format}`
    }
  },
  mounted () {
    this.isLoading = true
    if (Object.keys(this.data).length > 0) {
      this.client = this.data.client
      this.detail = this.data.detail
      // this.notes = this.data.notes
      this.project = this.data.project
      this.status_history = this.data.status_history
      this.isLoading = false
    } else {
      this.isLoading = false
    }
  }
}
</script>

<style scoped>
.w-100 {
  width: 100%;
}

.color-blue {
  color: #3185fc;
}

.flex-1 {
  flex: 1;
}

.label {
  color: #868686;
}

.color-red {
  background: linear-gradient(116.12deg, #d9272d 16.15%, #eb4600 83.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.text-title {
  font-size: 20px;
  margin-bottom: 1rem;
}

.detail-material-wrapper {
  background: #fafafa;
  border-radius: 20px;
}

.material-wrapper {
  background: #fff;
  border-radius: 20px;
}

.fs-12 {
  font-size: 12px;
}

.btn-primary {
  background: linear-gradient(
    116.12deg,
    #d9272d 16.15%,
    #eb4600 83.24%
  ) !important;
  border-radius: 100px;
}

.btn-disabled {
  background: #9a9a9a;
  border-radius: 100px;
}

.material-photo-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 57px;
  flex-shrink: 0;
}

.material-photo-wrapper img {
  width: 100%;
}

.material-photo-wrapper {
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 57px;
  height: 57px;
  border-radius: 8px;
}

.material-photo-wrapper img {
  width: 100%;
  height: 100%;
}

.photo-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-color: #fff;
}

.photo-wrapper img {
  width: 100%;
  height: 100%;
}

.note-konsultan {
  margin-top: 1rem;
  padding-top: 0.8rem;
  border-top: 1px dashed #9A9A9A;
  max-height: 120px;
  overflow: auto;
}

.wrapper-tukang {
  background: #F8F8F8;
  padding: 14px;
  border-radius: 10px;
}

.wrapper-file-photo {
  background: #F6F6F6;
  padding: 12px;
  border-radius: 12px;
  height: 12em;
  overflow-x: auto;
}

.wrapper-file {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  margin: 0px 12px;
  height: 140px;
}

.image-survey {
  width: 91px;
  height: 91px;
  object-fit: cover;
  border-radius: 8px;
}

.image-name {
  width: 91px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.steps {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.steps ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.steps li {
  display: flex;
  padding-bottom: 1rem;
}

.step-marker {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: bold;
  position: relative;
}

.step-marker:before {
  content: "";
  position: absolute;
  height: 35px;
  border-left: 2px dashed #ccc;
  bottom: -25px;
  z-index: 10;
}

.step-marker.completed:before {
  border-left: 2px dashed #10BD41;
}

.step-marker.end:before {
  border: none !important;
}

.skill-image {
  width: 30px;
  object-fit: contain;
}

.text-absen {
  background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.wrapper-tanggal-survey {
  flex-direction: column;
}

@media only screen and (max-width: 768px) {
  .wrapper-tanggal-survey {
    display: block !important;
  }

  .wrapper-absen {
    display: block !important;
    margin-top: 16px;
  }

  .wrapper-tukang {
    display: block !important;
  }
}

@media screen and (max-width: 600px) {
  .flex-sm-column {
    flex-direction: column;
  }

  .mb-sm-2 {
    margin-bottom: 1rem !important;
  }

  .mr-sm-0 {
    margin-right: 0 !important;
  }
}
</style>
