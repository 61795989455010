<template>
  <div class="wrapper-client">
    <div class="wrap-header">
      <img src="@/assets/img/wave.png" alt="wave" class="wave" />
      <div
        class="is-flex is-align-items-center is-justify-content-space-between wrap-action"
      >
        <div class="is-flex is-align-items-center">
          <p class="mr-1 tag-client" v-if="client.client_type_code && client.client_type_name">
            {{ client.client_type_name }}
          </p>
        </div>
        <div>
          <ButtonApp
            @click="editVendor"
            width="170px"
            height="34px"
            :isBold="true"
            :isTransparent="true"
            :isBorderWhite="true"
            >Edit Informasi Client</ButtonApp
          >
        </div>
      </div>
    </div>
    <div
      class="wrap-banner is-flex is-justify-content-space-between is-align-items-center"
    >
      <div>
        <p class="averta-bold label-20">{{ client.name }}</p>
        <p class="text-grey label-14">{{ client.company_name }}</p>
      </div>
      <img :src="client.logo" alt="logo" class="wrap-image" />
    </div>

    <SidebarClient
      :show="sidebarMenu === 'client'"
      :close="handleCloseMenu"
      @updateEdit="refreshData"
    />
  </div>
</template>

<script>
import ButtonApp from '@/components/button/ButtonApp.vue'
import SidebarClient from './SidebarClient.vue'

export default {
  name: 'BannerClient',
  components: {
    ButtonApp,
    SidebarClient
  },
  props: {
    client: Object,
    refreshData: Function
  },
  data () {
    return {
      sidebarMenu: null
    }
  },
  methods: {
    editVendor () {
      this.sidebarMenu = 'client'
      this.$store.commit('client/setPayloadClient', {
        typeSidebar: 'edit',
        dataForm: {
          ...JSON.parse(JSON.stringify(this.client)),
          id: this.$route.params.vendorId,
          imgFile: 'from-edit'
        },
        isEmptyForm: false
      })
      this.handleStoreSidebar(true)
    },
    handleCloseMenu () {
      this.sidebarMenu = null
      this.handleStoreSidebar(false)
    },
    handleStoreSidebar (showSidebar) {
      this.$store.commit('client/setPayloadClient', { showSidebar })
    }
  }
}
</script>

<style scoped>
.wrapper-client {
  background: linear-gradient(90deg, #ed4949 0%, #a40707 100%);
  border-radius: 24px;
}

.wrap-header {
  border-radius: 24px 24px 0px 0px;
  padding: 20px;
  position: relative;
}

.wave {
  object-fit: fill;
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  border-radius: 20px;
}

.wrap-action {
  position: relative;
}

.tag-client {
  background: #e1eeff;
  height: max-content;
  padding: 2px 12px;
  border-radius: 100px;
  color: #3185fc;
  font-family: "Averta-Bold";
}

.wrap-banner {
  background: #FAFAFA;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 20px;
  border: 1px solid #e1e1e1;
}

.wrap-image {
  height: 32px;
}
</style>
