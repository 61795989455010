<template>
    <div class="is-relative">
        <div class="date-follow-up is-clickable" :class="{'overdue': statusFollowUp === 'overdue'}" @click="onClickDate">
            <div class="wrap-card is-flex is-align-items-center is-justify-content-space-between p-3">
                <div class="is-flex is-align-items-center">
                    <ScheduleIcon :color="colorSchedule" width="15" height="15" />
                    <p class="averta-bold label-12 ml-2" :class="{'color-red': statusFollowUp === 'overdue', 'color-green': statusFollowUp === 'follow-up', 'color-gray': !statusFollowUp}">{{renderDateFollowUp}}</p>
                </div>

                <div v-if="statusFollowUp === null"></div>
                <div v-else class="wrap-symbol is-flex is-justify-content-center is-align-items-center is-clickable" :class="{'bg-red': statusFollowUp === 'overdue'}">
                    <p class="has-text-white averta-bold">{{statusFollowUp === 'overdue' ? '!' : '?'}}</p>
                </div>
            </div>
        </div>
        <div v-if="selectedFollowUp === surveyId && isOpenTooltip" class="custom-tooltip py-2 px-4">
            <div class="mt-2 is-flex is-justify-content-space-between is-align-items-center mb-3">
                <p class="averta-bold">Sudah Follow Up ?</p>
                <div @click="closeTooltip" class="is-clickable">
                    <b-icon icon="close" size="is-small"></b-icon>
                </div>
            </div>
            <div v-if="statusFollowUp ==='finish'" class="is-flex is-justify-content-space-between is-align-items-center">
                <ButtonApp :isSecondary="true" :isTransparent="true" class="flex-1 border-white" @click="openDatePicker()">
                  <p class="py-1 averta-regular has-text-white">Follow Up Lagi</p>
                </ButtonApp>
            </div>
            <div v-else class="is-flex is-justify-content-space-between is-align-items-center">
                <ButtonApp :isSecondary="true" :isBold="true" class="flex-1 mr-2 border-none" @click="handleFollowUp('complete')">
                  <p class="py-1 px-2">Sudah</p>
                </ButtonApp>
                <ButtonApp :isSecondary="true" :isTransparent="true" class="flex-1 border-white" @click="openDatePicker()">
                  <p class="py-1 averta-regular has-text-white">Ubah Tanggal</p>
                </ButtonApp>
            </div>
        </div>
        <div v-if="selectedFollowUp === surveyId && isOpenDate" class="wrap-date-follow-up" :class="{'last': isGroupLast}">
            <b-datepicker inline v-model="selectedDate"></b-datepicker>
            <div class="divider"></div>
            <div class="wrap-button py-3 is-flex is-justify-content-space-between">
                <ButtonApp :isSecondary="true" :isBold="true" @click="closeDatePicker" class="flex-1 ml-4 mr-2">
                  <p class="py-2">Batalkan</p>
                </ButtonApp>
                <ButtonApp :isDisabled="!selectedDate || isLoadingFollowUp" :isBold="true" class="flex-1 mr-4" @click="handleFollowUp('update')">
                  <p class="py-2">Pilih</p>
                </ButtonApp>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import { alertErrorMessage } from '@/helpers'
import { ScheduleIcon } from '@/components/icons'
import ButtonApp from '@/components/button/ButtonApp.vue'

export default {
  name: 'DateFollowUp',
  components: {
    ScheduleIcon,
    ButtonApp
  },
  props: {
    surveyId: {
      type: [String, Number]
    },
    statusFollowUpProps: {
      type: [String, Number]
    },
    dateFollowUpProps: {
      type: String
    },
    refreshData: {
      type: Function
    },
    isGroupLast: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      colorSchedule: '#868686',
      renderDateFollowUp: 'Belum Ditentukan',
      selectedDate: null,
      isLoadingFollowUp: false,
      isOpenDate: false,
      isOpenTooltip: false,
      statusFollowUp: null,
      dateFollowUp: null,

      focusedDate: new Date()
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      selectedFollowUp: 'report/getSelectedFollowUp'
    })
  },
  methods: {
    formatDate (date) {
      const resultDate = moment(date)
      if (date && resultDate.isValid()) {
        return moment(resultDate).format('DD MMM YYYY')
      }
      return '-'
    },
    onClickDate () {
      if (this.statusFollowUp) {
        this.openTooltip()
      } else {
        this.openDatePicker()
      }
    },
    openTooltip () {
      if (this.selectedFollowUp === this.surveyId) {
        this.$store.commit('report/setSelectedFollowUp', null)
        this.isOpenDate = false
        this.isOpenTooltip = false
      } else {
        this.$store.commit('report/setSelectedFollowUp', this.surveyId)
        this.isOpenTooltip = true
        this.isOpenDate = false
      }
    },
    closeTooltip () {
      this.isOpenTooltip = false
      this.$store.commit('report/setSelectedFollowUp', null)
    },
    openDatePicker () {
      if (this.dateFollowUp) {
        this.selectedDate = moment(this.dateFollowUp).toDate()
      } else {
        this.selectedDate = moment().add(1, 'days').toDate()
      }

      if (this.selectedFollowUp === this.surveyId) {
        if (this.isOpenTooltip) {
          this.isOpenTooltip = false
          this.isOpenDate = true
        } else {
          this.$store.commit('report/setSelectedFollowUp', null)
          this.isOpenDate = false
        }
      } else {
        this.$store.commit('report/setSelectedFollowUp', this.surveyId)
        this.isOpenDate = true
        this.isOpenTooltip = false
      }
    },
    closeDatePicker () {
      this.selectedDate = null
      this.isOpenDate = false
      this.isOpenTooltip = false
      this.$store.commit('report/setSelectedFollowUp', null)
    },
    handleFollowUp (type) {
      if (!this.selectedDate && type !== 'complete') {
        return alertErrorMessage('Pilih Tanggal Follow up')
      }
      this.isLoadingFollowUp = true
      const payload = {
        url: this.url,
        surveyId: this.surveyId,
        type
      }

      if (type === 'update') {
        payload.date = moment(this.selectedDate).format('YYYY-MM-DD')
      }

      this.$store
        .dispatch('report/putReportFollowUp', payload)
        .then((response) => {
          const res = response.data.data
          this.isLoadingFollowUp = false
          this.isOpenDate = false
          this.isOpenTooltip = false
          this.$store.commit('report/setSelectedFollowUp', null)

          const resDate = res.follow_up_date
          this.renderDateFollowUp = resDate ? this.formatDate(resDate) : '-'
          this.dateFollowUp = resDate

          this.conditionStatusFollowUp(res.follow_up_status || null)
        }).catch((err) => {
          this.isLoadingFollowUp = false
          alertErrorMessage(err)
        })
    },
    conditionStatusFollowUp (statusName) {
      if (statusName === null) {
        this.colorSchedule = '#868686'
      } else if (statusName === 'follow-up') {
        this.colorSchedule = '#10BD41'
      } else if (statusName === 'finish') {
        this.colorSchedule = '#000'
      } else if (statusName === 'overdue') {
        this.colorSchedule = '#D9272D'
      }

      if (statusName === null) {
        this.renderDateFollowUp = 'Belum Ditentukan'
      } else {
        this.renderDateFollowUp = this.formatDate(this.dateFollowUp)
      }
      this.statusFollowUp = statusName
    }
  },
  mounted () {
    this.dateFollowUp = this.dateFollowUpProps
    this.conditionStatusFollowUp(this.statusFollowUpProps)
  }
}
</script>

<style scoped>

.wrap-card {
    position: relative;
}

.date-follow-up {
    border: 1px solid #E1E1E1;
    border-radius: 12px;
    overflow: hidden;
    width: 11em;
}

.date-follow-up.overdue {
    background: #FBE9EA;
    border-color: #F4BEC0;
}

.wrap-symbol {
    width: 18px;
    height: 18px;
    border-radius: 100px;
    background: #868686;
}

.custom-tooltip {
    width: 250px;
    height: 100px;
    background: #474747;
    color: white;
    position: absolute;
    top: 50px;
    right: 0px;
    border-radius: 16px;
    z-index: 2;
}

.wrap-date-follow-up {
    position: absolute;
    right: 0px;
    border-radius: 12px;
    z-index: 5;
    box-shadow: 6px 7px 50px 0px #00000014;
    background: white;
}

.wrap-date-follow-up.last {
  bottom: 50px;
}

.divider {
    width: 90%;
    border: 1px solid #E1E1E1;
    margin: 0 auto;
}

.wrap-button {
    background: white;
    border-radius: 0px 0px 12px 12px;
}

.img-vendor {
    width: 50px;
    height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.img-vendor img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

</style>

<style>
.wrap-date-follow-up .datepicker .dropdown-content {
    box-shadow: none !important;
    border-radius: 12px 12px 0px 0px;
    border-bottom: none;
}
</style>
