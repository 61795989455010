<template>
    <div class="wrap-report">
        <TitleBar :title-stack="titleStack" class="px-6 py-5 mb-5" />
        <div class="wrap-menu-report columns is-flex-wrap-wrap mx-6">
            <div class="card-menu column is-3 mr-4" @click="laporanFollowUp">
                <div class="wrap-icon mb-3 is-flex is-justify-content-center is-align-items-center">
                    <IconMenuReport />
                </div>
                <p class="title-menu averta-black color-black-primary">List Follow Up Client</p>
                <p class="text-grey">Laporan progress proyek Client</p>
                <div class="dot-1"></div>
                <div class="dot-2"></div>
            </div>
            <div class="card-menu column is-3" @click="reportProgress">
                <div class="wrap-icon mb-3 is-flex is-justify-content-center is-align-items-center">
                    <CountDownIcon />
                </div>
                <p class="title-menu averta-black color-black-primary">Report Progress</p>
                <p class="text-grey">Ringkasan progress setiap proyek dan survey</p>
                <div class="dot-1"></div>
                <div class="dot-2"></div>
            </div>
        </div>
    </div>
</template>

<script>
import TitleBar from '@/components/TitleBar'
import { IconMenuReport, CountDownIcon } from '@/components/icons'

export default {
  name: 'Report',
  components: {
    TitleBar,
    IconMenuReport,
    CountDownIcon
  },
  computed: {
    titleStack () {
      return ['Report']
    }
  },
  methods: {
    laporanFollowUp () {
      this.$router.push({ path: '/am/report/follow-up' })
    },
    reportProgress () {
      this.$router.push({ path: '/am/report/progress' })
    }
  }
}
</script>

<style scoped>
.wrap-report {
    height: 100vh;
    width: 100%;
}

.wrap-menu-report {
    width: auto;
}

.card-menu {
    border: 1px solid #E1E1E1;
    border-radius: 20px;
    position: relative;
    height: 145px;
    overflow: hidden;
    cursor: pointer;
    transition: 0.1s all ease-in-out;
}

.card-menu .title-menu {
  font-size: 16px;
  transition: 0.1s all ease-in-out;
  transform-origin: center left;
}

.card-menu:hover {
    border: 1px solid #E10009;
}

.card-menu:hover .title-menu {
    transform: scale(1.2);
}

.wrap-icon {
    width: 50px;
    height: 50px;
    border-radius: 100px;
    background: #FC31311A;
}

.dot-1 {
    width: 70px;
    height: 70px;
    border-radius: 100px;
    background: #FC31311A;
    position: absolute;
    top: -18px;
    right: -20px;
}

.dot-2 {
    width: 70px;
    height: 70px;
    border-radius: 100px;
    background: #FC31311A;
    position: absolute;
    top: -48px;
    right: 14px;
}

</style>
