<template>
    <div class="wrap-material">
        <!-- Card -->
        <div class="columns mt-2 mb-5">
            <div class="column card card-red p-5 m-2 is-flex is-align-items-center is-justify-content-center">
                <img :src="require('@/assets/img/wave-card.png')" alt="wave" class="wave" />
                <div class="body-card-wrapper has-text-centered">
                    <p class="label-14">Total Qty Material Dibutuhkan</p>
                    <p class="label-24 averta-bold">{{ data.card.total_need }} Material</p>
                </div>
            </div>
            <div class="column card card-red p-5 m-2 is-flex is-align-items-center is-justify-content-center">
                <img :src="require('@/assets/img/wave-card.png')" alt="wave" class="wave" />
                <div class="body-card-wrapper has-text-centered">
                    <p class="label-14">Total Qty Material Dipesan</p>
                    <p class="label-24 averta-bold">{{ data.card.total_process }} Material</p>
                </div>
            </div>
            <div class="column card card-red p-5 m-2 is-flex is-align-items-center is-justify-content-center">
                <img :src="require('@/assets/img/wave-card.png')" alt="wave" class="wave" />
                <div class="body-card-wrapper has-text-centered">
                    <p class="label-14">Total Qty Material Terkirim</p>
                    <p class="label-24 averta-bold">{{ data.card.total_fulfilled }} Material</p>
                </div>
            </div>
            <div class="column card card-red p-5 m-2 is-flex is-align-items-center is-justify-content-center">
                <img :src="require('@/assets/img/wave-card.png')" alt="wave" class="wave" />
                <div class="body-card-wrapper has-text-centered">
                    <p class="label-14">Total Actual Cost</p>
                    <p class="label-24 averta-bold">{{ formatThousand(data.card.total_actual_cost) }}</p>
                </div>
            </div>
        </div>
        <!-- Card -->

        <!-- Daftar Material -->
        <div class="is-flex is-justify-content-space-between is-align-items-center my-6">
            <div>
                <p class="averta-black">Daftar Material Proyek</p>
                <p>Material berikut adalah material yang harus diproses oleh purchasing</p>
            </div>
            <button class="btn-tambah averta-bold label-14 is-clickable" @click="addStore()">Tambah Toko</button>
        </div>

        <div class="has-text-centered">
            <table class="table is-hoverable is-fullwidth">
                <tr class="has-background-grey has-text-white label-14 averta-bold">
                    <td>PEMESANAN</td>
                    <td>NO</td>
                    <td>FOTO</td>
                    <td>MATERIAL</td>
                    <td>UOM (SATUAN)</td>
                    <td>QTY</td>
                    <td>QTY DIPESAN</td>
                    <td>SISA MATERIAL</td>
                    <td>TOTAL</td>
                </tr>
                <tr v-for="material, indexMaterial in data.material" :key="`${indexMaterial}-material`" class="label-12">
                    <td>
                        <input type="checkbox" class="checkbox" :id="material.id" @change="event => addMaterial(event, material)" :disabled="material.remaining_material === 0 && 'disabled'">
                    </td>
                    <td>{{ indexMaterial + 1 }}</td>
                    <td>
                        <img @click="handlePreviewImage(material.picture)" :src="material.picture" v-if="material.picture" class="image-material is-clickable">
                        <img :src="require('@/assets/img/empty-img-2.png')" v-else class="image-material">
                    </td>
                    <td>{{ material.name }}</td>
                    <td>{{ material.uom }}</td>
                    <td>{{ material.qty }}</td>
                    <td>{{ material.qty_booked }}</td>
                    <td class="text-merah averta-bold">
                      <p v-if="material.remaining_material === 0" class="has-text-success averta-bold">Terkirim</p>
                      <p v-else>{{ material.remaining_material }}</p>
                    </td>
                    <td class="averta-bold">{{ formatThousand(material.total_price) }}</td>
                </tr>
            </table>
        </div>
        <!-- Daftar Material -->

        <!-- Floating button bottom -->
        <div class="wrap-floating-bottom is-flex is-justify-content-space-between is-align-items-center" v-if="selectMaterial.length > 0">
            <div class="is-flex is-justify-content-space-between is-align-items-center">
                <GroupMaterial class="mr-3"/>
                <div class="has-text-white">
                    <p class="label-12 averta-regular">Jumlah Material dipilih</p>
                    <p class="label-16 averta-black">{{ selectMaterial.length }} Material</p>
                </div>
            </div>
            <button class="btn-lanjutkan averta-bold label-14 is-clickable" @click="showSidebar()">
                Lanjutkan
            </button>
        </div>
        <!-- Floating button bottom -->

        <!-- Modal Store -->
        <ModalStores :showModal="activeModal" @closeModal="addStore()"/>
        <!-- Modal Store -->

        <!-- Sidebar -->
        <b-sidebar type="is-light" :fullheight="true" :fullwidth="true" :overlay="true" :right="true" :can-cancel="false" v-model="sidebarActive">
          <div class="p-6 has-background-white wrap-sidebar-content" v-if="selectMaterial.length > 0">
            <!-- Header Sidebar -->
            <div class="is-flex is-justify-content-space-between mb-5">
              <div>
                <p class="averta-black label-30">Lengkapi Data Berikut</p>
                <p class="label-14 averta-regular">Silakan isi nama toko material, harga satuan, kuantitas material dan ongkos kirim material</p>
              </div>
              <div @click="showSidebar()" class="is-clickable">
                <b-icon icon="close" size="is-small" />
              </div>
            </div>
            <!-- Header Sidebar -->

            <!-- Add New Toko -->
            <div class="wrap-new-store p-5 is-flex is-justify-content-space-between is-align-items-center">
              <div>
                <p class="averta-black label-16">Tambah Toko Material Baru</p>
                <p class="label-16 averta-regular">Silakan tambah toko material yang belum terdaftar dalam sistem</p>
              </div>
              <div @click="addStore()">
                <button class="btn-tambah-toko is-clickable"><p class="averta-bold">Tambah Toko</p></button>
              </div>
            </div>
            <!-- Add New Toko -->

            <!-- Choose Store -->
            <div class="columns">
              <div class="column">
                <p class="has-text-grey mb-1 averta-regular">Toko Material</p>
                <div class="wrap-choose-store is-flex is-align-items-center is-justify-content-space-between is-clickable" @click="showModalStore()">
                  <p class="text-merah averta-bold label-16">{{ selectedStore ? selectedStore.name : 'Pilih Toko Material' }}</p>
                  <ArrowRightIcon color="#D9272D"/>
                </div>
                <div v-if="validation.store" class="wrap-error mt-2 is-flex is-align-items-center p-2">
                  <AlertIcon class="mr-3" />
                  <p class="averta-regular">Toko material belum dipilih</p>
                </div>
              </div>
              <div class="column">
                <p class="has-text-grey mb-1 averta-regular">Masukkan Ekspedisi (<i>bisa diinput terpisah</i>)</p>
                <input type="text" class="wrap-expedition label-14 py-3 px-5" placeholder="Isi Ekspedisi" v-model="expeditionName">
              </div>
              <div class="column">
                <p class="has-text-grey mb-1 averta-regular">Masukkan Ongkos Kirim (<i>bisa diinput terpisah</i>)</p>
                <div class="is-flex is-align-items-center wrap-ongkir-parent">
                  <input type="text" class="averta-bold label-14 wrap-ongkir" placeholder="0" v-model="ongkirPrice" @input="formatPrice(ongkirPrice, 'ongkir')">
                  <PlusWithBorder />
                </div>
              </div>
            </div>
            <!-- Choose Store -->

            <!-- Table -->
            <div class="wrap-table-buy overflow-auto">
              <table class="table is-hoverable is-fullwidth has-text-centered averta-regular">
                <tr class="averta-bold has-background-grey has-text-white">
                  <td>NO</td>
                  <td>FOTO</td>
                  <td>MATERIAL</td>
                  <td>QTY (ESTIMASI)</td>
                  <td>UOM (SATUAN)</td>
                  <td>HARGA/PCS</td>
                  <td>QTY DIPESAN</td>
                  <td>TOTAL HARGA BELI</td>
                  <td>ACTION</td>
                </tr>
                <tr v-for="selected, indexSelected in selectMaterial" :key="`${indexSelected}-selected-material`">
                  <td>{{ indexSelected + 1 }}</td>
                  <td>
                    <img :src="selected.picture" v-if="selected.picture" class="image-material">
                    <img :src="require('@/assets/img/empty-img-2.png')" v-else class="image-material">
                  </td>
                  <td>{{ selected.name }}</td>
                  <td>
                    <p>{{ selected.qty }}</p>
                    <p class="text-merah ">Sisa Material : <span class="averta-bold">{{ selected.remaining_material }}</span></p>
                  </td>
                  <td>{{ selected.uom }}</td>
                  <td>
                    <div class="is-flex is-align-items-center wrap-price" :class="{'wrap-price-error' : findIndexError(indexSelected, 'unit-price')}">
                      <p>Rp</p>
                      <input type="text" class="wrap-price-input averta-bold label-14" placeholder="0" v-model="selected.unit_price" @input="formatPrice(selected.unit_price, 'harga-material', indexSelected)">
                      <EditIcon />
                    </div>
                  </td>
                  <td>
                    <div class="is-flex is-align-items-center wrap-price" :class="{'wrap-price-error' : findIndexError(indexSelected, 'qty-order')}">
                      <input type="text" class="wrap-price-input averta-bold label-14" placeholder="0" v-model="selected.qty_order" @input="formatPrice(selected.qty_order, 'qty-order', indexSelected)">
                      <PlusWithBorder />
                    </div>
                  </td>
                  <td class="averta-bold">{{ totalHarga(selected.unit_price, selected.qty_order, indexSelected) }}</td>
                  <td>
                    <div @click="deleteMaterial(selected.id, indexSelected)">
                      <ActionIcon class="is-clickable" />
                    </div>
                  </td>
                </tr>
              </table>
            </div>
            <!-- Table -->
          </div>
          <!-- Bottom -->
          <div class="is-flex is-justify-content-space-between is-align-items-center mt-5 wrap-proses">
            <div class="is-flex is-justify-content-space-between is-align-items-center">
              <div class="is-flex is-justify-content-space-between is-align-items-center mr-6">
                <GroupMaterial class="mr-3"/>
                <div>
                    <p class="label-12 averta-regular">Jumlah Material dipilih</p>
                    <p class="label-16 averta-black">{{ selectMaterial.length }} Material</p>
                </div>
              </div>
              <div>
                <p class="label-12 averta-regular">Total Harga Beli</p>
                <p class="averta-black label-16">{{ totalHargaBeli }}</p>
              </div>
            </div>
            <div class="is-flex is-justify-content-space-between is-align-items-center">
              <div v-if="validation.qtyBooked || validation.unitPrice" class="wrap-error mt-2 is-flex is-align-items-center py-1 px-3 mr-3">
                <AlertIcon class="mr-3" />
                <p>Lengkapi Harga/pcs dan QTY Dipesan</p>
              </div>
              <button class="btn-tambah-toko averta-bold label-14 is-clickable" @click="orderMaterial()">
                  Proses
              </button>
            </div>
          </div>
          <!-- Bottom -->

          <!-- Modal Toko Material -->
          <b-modal :can-cancel="false" v-model="modalStore" width="40vw">
            <div class="content-modal">
              <div class="modal-header is-flex is-justify-content-space-between is-align-items-center">
                <div class="is-flex is-align-items-center">
                  <StoreIconLinear class="mr-3" />
                  <p class="averta-bold has-text-white label-20">Toko Material</p>
                </div>
                <div class="is-clickable" @click="showModalStore()">
                  <b-icon icon="close"></b-icon>
                </div>
              </div>
              <div class="modal-body p-5">
                <!-- Search Store Material -->
                <div>
                  <p class="label-14 averta-regular">Pilih Toko Material</p>
                  <b-input
                    class="mt-2 mb-4"
                    v-model="searchStore"
                    type="text"
                    icon="magnify"
                    placeholder="Cari Toko"
                  />
                  <div class="wrap-new-store py-2 px-4 is-flex is-justify-content-space-between is-align-items-center">
                    <p class="averta-black label-14">Tambah Toko Material</p>
                    <div @click="addStore()">
                      <button class="btn-tambah-toko is-clickable"><p class="averta-bold">Tambah Toko</p></button>
                    </div>
                  </div>
                </div>
                <!-- Search Store Material -->

                <div class="divider"></div>

                <!-- List Store Material -->
                <div class="wrap-list-store">
                  <div class="wrap-item-store p-3 is-flex is-justify-content-space-between is-align-items-center is-clickable mb-2" v-for="store, indexStore in listStore" :key="`${indexStore}-store-material`" @click="selectStoreMaterial(store)">
                    <div class="is-flex is-justify-content-space-between is-align-items-center">
                      <StoreIconLinear color="#D9272D" class="mr-3" />
                      <div>
                        <p class="averta-bold label-14">{{ store.name }}</p>
                        <p class="averta-regular">{{ store.address ? store.address : '-' }}</p>
                      </div>
                    </div>
                    <div>
                      <PlusGradient />
                    </div>
                  </div>
                </div>
                <!-- List Store Material -->
              </div>
            </div>
          </b-modal>
          <!-- Modal Toko Material -->
        </b-sidebar>
        <!-- Sidebar -->

        <!-- Modal Preview Image -->
        <ModalPreviewImage :isActive="previewImage" :urlOneImage="imagePreview" @closeModal="closePreviewImage()" />

        <!-- Loading -->
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false">
          <SpinnerIcon />
        </b-loading>
        <!-- Loading -->
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { formatCurrency } from '@/helpers'
import { GroupMaterial, ArrowRightIcon, EditIcon, PlusWithBorder, ActionIcon, PlusGradient, StoreIconLinear, AlertIcon, SpinnerIcon } from '@/components/icons'
import ModalStores from './../../components/modal/ModalStores.vue'
import orderMaterialImage from '@/assets/img/survey-selesai.png'
import ModalPreviewImage from '@/components/modal/ModalPreviewImage'

export default {
  name: 'TabDaftarMaterial',
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  components: {
    GroupMaterial,
    ModalStores,
    ArrowRightIcon,
    EditIcon,
    PlusWithBorder,
    ActionIcon,
    PlusGradient,
    StoreIconLinear,
    AlertIcon,
    SpinnerIcon,
    ModalPreviewImage
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    totalHargaBeli () {
      let total = 0

      this.selectMaterial.forEach((item, index) => {
        if (item.total_buy !== null) {
          total = total + item.total_buy
        }
      })

      return this.formatThousand(total)
    }
  },
  data () {
    return {
      selectMaterial: [],
      activeModal: false,
      sidebarActive: false,
      ongkirPrice: null,
      expeditionName: null,
      selectedStore: null,
      modalStore: false,
      listStore: null,
      searchStore: '',
      validation: {
        store: false,
        unitPrice: false,
        qtyBooked: false
      },
      indexUnitPrice: [],
      indexQtyOrder: [],
      isLoading: false,
      previewImage: false,
      imagePreview: null
    }
  },
  methods: {
    formatThousand (amount) {
      return formatCurrency(amount)
    },
    addMaterial (event, data) {
      if (event.target.checked === true) {
        this.selectMaterial.push({
          ...data,
          unit_price: null,
          qty_order: null,
          total_buy: null
        })
      } else {
        let index = -1

        for (let i = 0; i < this.selectMaterial.length; i++) {
          if (this.selectMaterial[i].id === data.id) {
            index = i
            break
          }
        }

        if (index !== -1) {
          this.selectMaterial.splice(index, 1)
        }
      }
    },
    getListStore (search) {
      const payload = {
        url: this.url,
        search
      }

      this.$store
        .dispatch('store/getListStoreModal', payload)
        .then((response) => {
          this.listStore = response.data.data
        })
        .catch((error) => {
          console.log(error)
          this.$buefy.toast.open({
            message: 'Gagal memuat data daftar toko material, silahkan coba lagi',
            type: 'is-danger'
          })
        })
    },
    addStore () {
      this.activeModal = !this.activeModal
    },
    showSidebar () {
      this.sidebarActive = !this.sidebarActive
    },
    showModalStore () {
      this.modalStore = !this.modalStore
      if (this.modalStore === true) {
        this.getListStore()
      }
    },
    formatPrice (amount, type, indexHargaMaterial) {
      // Menghapus karakter selain angka
      const rawPrice = amount.replace(/[^0-9]/g, '')

      // Menambahkan koma setiap tiga digit
      amount = rawPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

      if (type === 'ongkir') {
        this.ongkirPrice = amount
      } else if (type === 'harga-material') {
        this.selectMaterial[indexHargaMaterial].unit_price = amount
      } else if (type === 'qty-order') {
        this.selectMaterial[indexHargaMaterial].qty_order = rawPrice
      }
    },
    totalHarga (amount, qty, index) {
      if (amount) {
        // Menghapus koma dan karakter selain angka
        const cleanedPrice = amount.replace(/[^0-9]/g, '')
        this.selectMaterial[index].total_buy = cleanedPrice * qty
        return this.formatThousand(cleanedPrice * qty)
      } else {
        return this.formatThousand(0)
      }
    },
    deleteMaterial (id, index) {
      this.selectMaterial.splice(index, 1)

      const checkbox = document.getElementById(`${id}`)
      if (checkbox) {
        checkbox.checked = false
      }

      if (this.selectMaterial.length === 0) {
        this.sidebarActive = false
      }
    },
    findIndexError (index, type) {
      if (type === 'unit-price') {
        const isFind = this.indexUnitPrice.indexOf(index)
        if (isFind !== -1) {
          return true
        } else {
          return false
        }
      }

      if (type === 'qty-order') {
        const isFind = this.indexQtyOrder.indexOf(index)
        if (isFind !== -1) {
          return true
        } else {
          return false
        }
      }
    },
    selectStoreMaterial (store) {
      this.selectedStore = store
      this.modalStore = false
    },
    orderMaterial () {
      if (this.selectedStore === null) {
        this.validation.store = true
      } else {
        this.validation.store = false
      }

      this.indexUnitPrice = []
      this.indexQtyOrder = []
      this.validation.qtyBooked = false
      this.validation.unitPrice = false
      this.selectMaterial.forEach((item, index) => {
        // Validation QTY Order
        if (item.qty_order === null || +item.qty_order === 0 || item.qty_order === '') {
          this.validation.qtyBooked = true
          this.indexQtyOrder.push(index)
        } else if (item.qty_order > item.remaining_material) {
          this.validation.qtyBooked = true
          this.indexQtyOrder.push(index)
          this.$buefy.toast.open({
            message: 'Maaf, QTY Dipesan tidak boleh melebihi sisa material',
            type: 'is-danger'
          })
        }

        // Validation Unit Price atau Harga/PCS
        if (item.unit_price === null || +item.unit_price === 0 || item.unit_price === '') {
          this.validation.unitPrice = true
          this.indexUnitPrice.push(index)
        }
      })

      if (!this.validation.qtyBooked && !this.validation.unitPrice && !this.validation.store) {
        const data = {
          material_store_id: this.selectedStore.id,
          expedition_price: this.ongkirPrice !== null && this.ongkirPrice !== '' ? +this.ongkirPrice.replace(/[^0-9]/g, '') : null,
          expedition_name: this.expeditionName
        }

        data.materials = this.selectMaterial.map((item, index) => {
          return {
            qoutation_material_id: item.id,
            unit_price: +item.unit_price.replace(/[^0-9]/g, ''),
            qty: +item.qty_order
          }
        })

        const payload = {
          url: this.url,
          project_id: this.$route.params.projectId,
          data
        }

        this.$swal({
          imageUrl: orderMaterialImage,
          imageHeight: 250,
          imageAlt: 'order-material',
          width: '600px',
          padding: '10px 10px 30px 10px',
          title: '<p class="averta-bold label-18">Pastikan Data Sudah Benar</p>',
          html: '<p class="label-14">Data yang sudah di proses tidak dapat diubah. Pastikan data sudah benar</p>',
          showCancelButton: true,
          confirmButtonText: 'Proses',
          cancelButtonText: 'Cek Lagi',
          customClass: {
            confirmButton: 'btn-hapus-detail-component',
            cancelButton: 'btn-batal-detail-component'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            this.isLoading = true
            this.$store
              .dispatch('project/postMaterial', payload)
              .then((response) => {
                this.selectedStore = null
                this.ongkirPrice = null
                this.expeditionName = null
                this.selectMaterial = []
                this.sidebarActive = false
                this.isLoading = false
                this.$emit('refreshData')
                this.$buefy.toast.open({
                  message: 'Berhasil order material',
                  type: 'is-success'
                })
              })
              .catch((error) => {
                console.log(error)
                this.isLoading = false
                this.$buefy.toast.open({
                  message: 'Gagal order material, silahkan coba lagi',
                  type: 'is-danger'
                })
              })
          }
        })
      }
    },
    handlePreviewImage (url) {
      if (url) {
        this.previewImage = true
        this.imagePreview = url
      }
    },
    closePreviewImage () {
      this.imagePreview = null
      this.previewImage = false
    }
  },
  watch: {
    searchStore (newVal) {
      this.getListStore(newVal)
    },
    selectMaterial (newVal) {
      if (newVal.length === 0) {
        this.selectedStore = null
        this.ongkirPrice = null
        this.expeditionName = null
      }
    }
  }
}
</script>

<style scoped>

.wrap-material {
    margin-bottom: 100px;
}

.card-red {
  background: linear-gradient(90deg, #891313 0%, #D92727 99.97%);
  border-radius: 20px;
  color: white;
}

.wave {
  object-fit: cover;
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  border-radius: 20px;
}

.body-card-wrapper {
  position: relative;
  z-index: 1;
}

.btn-tambah {
    border: 1px solid #EB4600;
    background: white;
    padding: 12px 32px;
    border-radius: 20px;
    color: #EB4600;
}

.btn-tambah-toko {
    background: var(--gradient-red-red-primary-button, linear-gradient(116deg, #D9272D 16.15%, #EB4600 83.24%));
    padding: 12px 32px;
    border-radius: 20px;
    color: white;
    border: none;
}

.checkbox {
    width: 18px;
    height: 18px;
}

.image-material {
    width: 60px;
}

.btn-lanjutkan {
    border: none;
    background: white;
    padding: 12px 32px;
    border-radius: 20px;
    color: #EB4600;
}

.wrap-floating-bottom {
    background: var(--red-shade-primary, #911A1E);
    padding: 20px;
    border-radius: 18px;
    position: fixed;
    bottom: 60px;
    left: 110px;
    width: 90%;
}

.table tr, td {
  border: 1px solid #C0C0C0;
  border-collapse: collapse;
}

.table td {
  vertical-align: middle;
  padding: 10px 20px;
}

.wrap-sidebar-content {
  height: auto;
  overflow: auto;
  margin-bottom: 70px;
}

.wrap-new-store {
  background: var(--gradient-red-red-05, linear-gradient(90deg, #FFDEDF 0%, #FCDEFF 100%));
  border-radius: 20px;
  margin-bottom: 40px;
}

.wrap-choose-store {
  padding: 10px 24px;
  border: 1px solid #C0C0C0;
  background-color: white;
  border-radius: 12px;
}

.wrap-ongkir-parent {
  border: 1px solid #C0C0C0;
  padding: 4px 12px;
  border-radius: 10px;
}

.wrap-ongkir {
  width: 100%;
  border-radius: 12px;
  border: none;
  padding: 8px 12px;
}

.wrap-ongkir:focus {
  outline: none;
}

.wrap-expedition {
  width: 100%;
  border-radius: 12px;
  border: none;
  border: 1px solid #C0C0C0;
}

.wrap-expedition:focus {
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.wrap-price {
  border: 1px solid #C0C0C0;
  padding: 4px 12px;
  border-radius: 10px;
}

.wrap-price-error {
  background: rgba(255, 0, 0, 0.10);
  padding: 4px 12px;
  border: 1px solid #D9272D;
  border-radius: 10px;
}

.wrap-price-error input {
  background: transparent;
}

.wrap-price-input {
  width: 100%;
  border-radius: 12px;
  border: none;
  padding: 8px 12px;
}

.wrap-price-input:focus {
  outline: none;
}

.wrap-proses {
  border-top: 1px solid #C0C0C0;
  position: absolute;
  width: 100%;
  bottom: 0px;
  padding: 12px;
  background: white;
  height: 80px;
}

.content-modal {
  width: 100%;
}

.modal-header {
  background: #911a1e;
  padding: 24px;
  color: white;
  border-radius: 12px 12px 0px 0px;
}

.modal-body {
  background: white;
  border-radius: 0px 0px 12px 12px;
  height: 70vh;
  overflow-y: auto;
}

.divider {
  border-bottom: 2px dashed #e1e1e1;
  margin-top: -14px;
  margin-bottom: 14px;
}

.wrap-item-store:hover {
  background: #d2d2d21c;
  border-radius: 12px;
}

.wrap-error {
  background: rgba(255, 0, 0, 0.10);
  border-radius: 8px;
}

</style>

<style>

.b-sidebar .sidebar-content.is-light {
  background: white !important;
}

</style>
